body {
	color: var(--white);
	padding: 0;
	margin: 0;
	overflow-x: auto;
	background-color: var(--body);
}

h2,
h3,
h4,
h5,
h6 {
	color: var(--neutral);
	font-family: 'Inter', sans-serif;
}

h1 {
	font-size: 3rem;
	font-weight: 600;
	line-height: 120%;
}

h2 {
	font-weight: 600;
	font-size: 2.5rem;
	line-height: 120%;
}

h3 {
	font-weight: 600;
	font-size: 2rem;
	line-height: 120%;
}

h4 {
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 130%;
}

h5 {
	font-size: 1.3rem;
	font-weight: 600;
	line-height: 130%;
}

h6 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 130%;
	font-family: 'Inter', sans-serif;
}

.d1{
	font-size: 5rem;
	font-weight: 600;
	line-height: 120%;
	display: block;
}
.d2{
	font-size: 4.5rem;
	font-weight: 600;
	line-height: 120%;
	display: block;
}
.d3{
	font-size: 4rem;
	font-weight: 600;
	line-height: 120%;
	display: block;
}
.d4{
	font-size: 3.5rem;
	font-weight: 600;
	line-height: 120%;
	display: block;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}
a {
	text-decoration: none;
	margin: 0;
	color: var(--white);
}
a:hover {
	text-decoration: none;
	color: var(--white);
}
input:focus{
	color: var(--white);
}
input{
	color: var(--white);
}
p {
	font-size: 18px;
	line-height: 1.5;
	margin-bottom: 0;
	color: var(--pragraph-color);
	font-family: 'Inter', sans-serif;
}
.pb__20{
	padding-bottom: 20px;
}
.pt__20{
	padding-top: 20px;
}
.pt__15{
	padding-top: 15px;
}
.pb-250{
	padding-bottom: 200px;
}
.pt-120 {
	padding-top: 120px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pt-80 {
	padding-top: 80px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pt__60 {
	padding-top: 60px;
}
.pb__60 {
	padding-bottom: 60px;
}
.pt__40 {
	padding-top: 40px;
}
.pb__40 {
	padding-bottom: 40px;
}
.pl__40{
	padding-left: 40px;
}
.pr__40{
	padding-right: 40px;
}
.pb__32{
	padding-bottom: 32px;
}
.mt__60{
	margin-top: 60px;
}
.mb__60{
	margin-bottom: 60px;
}
.mb-120{
	margin-bottom: 200px;
}
.mb24{
	margin-bottom: 24px !important;
}
.fw500{
	font-weight: 600 !important;
}
.mb__40{
	margin-bottom: 40px;
	@include breakpoint(max-lg){
		margin-bottom: 30px;
	}
	@include breakpoint(max-md){
		margin-bottom: 20px;
	}
}
/*-Responsive-*/
/*-Responsive-*/
@media screen and (max-width: 991px) {
	.pt-120 {
		padding-top: 80px;
	}
	.pb-120 {
		padding-bottom: 80px;
	}
	.pt-80 {
		padding-top: 60px;
	}
	.pb-80 {
		padding-bottom: 60px;
	}
}
@media screen and (max-width: 767px) {
	.pt-120 {
		padding-top: 70px;
	}
	.pb-120 {
		padding-bottom: 70px;
	}
	.pt__60 {
		padding-top: 40px;
	}
	.pb__60 {
		padding-bottom: 40px;
	}
	.pl__40{
		padding-left: 30px;
	}
	.pr__40{
		padding-right: 30px;
	}
}
@media screen and (max-width: 575px) {
	.pt-120 {
		padding-top: 60px;
	}
	.pb-120 {
		padding-bottom: 60px;
	}
	.pt-80 {
		padding-top: 60px;
	}
	.pb-80 {
		padding-bottom: 60px;
	}
}
/*-Responsive-*/
/*-Responsive-*/
.danger{
	color: var(--danger) !important;
}
.theme{
	color: #41E295 !important;
}
.success{
	color: #005C30 !important;
}
.theme2{
	color: #EDCB50 !important;
}
.title-white {
	color: var(--white);
}
.bgsection{
	background: var(--bgsection);
}
.darkbg {
	background: var(--darkbg);
}
.bannerbg {
	background: var(--banner);
}
.bg__white {
	background: var(--whitebgdark);
}
.center {
	text-align: center;
	margin: 0 auto;
}
.base{
	color: #452FF4 !important;
}



//page scroll bar add
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #462ff453;
	border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #462ff473;
	border-radius: 10px;
}
//page scroll bar add


.twitter{
	background: var(--secoundary-color) !important;
}
.twitch{
	background: rgb(110, 60, 210) !important;
}
.youtube{
	background: rgb(172, 46, 46) !important;
}
.insta{
	background: rgb(207, 93, 93) !important;
}
.lind{
	background: rgb(78 131 228) !important;
}
.face{
	background: rgb(27, 114, 244) !important;
}
.material-symbols-outlined {
	font-family: 'Material Symbols Outlined';
	font-weight: 200;
	font-style: normal;
	font-size: 0.9rem;
	color: red;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
  }

/*--Section Header Start--*/
/*--Section Header Start--*/
.section__header{
	 h2{
		margin-bottom: 24px;
		color: var(--themetext);
		@include breakpoint(max-xl){
			font-size: 2rem;
			margin-bottom: 16px;
		}
		@include breakpoint(max-xs){
			font-size: 1.7rem;
		}
		.basecon{
			color: var(--achackcion);
		}
	 }
	 
	 p{
		font-size: 18px;
		color: var(--themetext);
		font-weight: 400;
		@include breakpoint(max-xl){
			font-size: 1rem;
		}
	 }
}
.section__center{
	text-align: center;
	margin: 0 auto;
	max-width: 600px;
}
/*--Section Header End--*/
/*--Section Header End--*/
