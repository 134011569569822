//google font source
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700;800&display=swap");

:root {
  --body: #ffffff;
  --themetext: #354764;
  --darkbg: #08162e;
  --banner: #08162e;
  --white: #ffffff;
  --base: #452ff4;
  --basetwo: #005c30;
  --basethree: #6b4a00;
  --danger: #ed5050;
  --themeneed: #a21111;
  --success: #3fca90;
  --worning: #edcb50;
  --info: #3f7fca;
  --quitborder: #dcd8fc;
  --quitcolor: #887af8;
  --quitibg: #edebfd;
  --quitboxbg: #f6f5fe;
  --neutral: #1e1e1f;
  --bgsection: #f6f5ff;
  --sunborder: #cdc7fc;
  --text-base: #41e295;
  --whitebgdark: #ffffff;
  --countptext: #243757;
  --countright: #c2c7d0;
  --dots: #dfe2e6;
  --dotsave: #dfe2e6;
  --subborder: #eceafe;
  --box1: #eceafe;
  --box2: #e6efea;
  --box3: #d9d9d9;
  --fwork: #f5f4fe;
  --robit: #3021ab;
  --boxborder: #b3aafa;
  --planbuttonborder: #96bcaa;
  --priceborder: #ebedf0;
  --inborder: #c2b596;
  --footertext: #f5f6f7;
  --intellicon: #ffaf00;
  --inbox1: #dbd7fd;
  --inbox2: #b1f3d4;
  --inbox3: #ffde96;
  --inbox4: #f0ede6;
  --coutbg1: #f4f3fe;
  --coutbg2: #f2f7f4;
  --coutbg3: #f2eaea;
  --coutbg4: #f7f6f2;
  --coubor1: #d99d9d;
  --deeptheme: #b7aefb;
  --pribox1: #f6f5fe;
  --pribox2: #f2f7f5;
  --pribox3: #faf3f3;
  --pribox4: #f6e7e7;
  --priboxcolor4: #630a0a;
  --pricloud4: #e7c2c2;
  --priboxicon: #f6e7e7;
  --soc1: #f5f4fe;
  --soc2: #f2f7f4;
  --soc3: #f7f6f2;
  --soc4: #faf3f3;
  --socbor1: #d2cdfc;
  --socbor2: #c2d8cd;
  --socbor3: #dbd3c2;
  --socbor4: #e8c6c6;
  --facebook: #452ff4;
  --instagram: #005c30;
  --twitter: #6b4900;
  --linkedin: #a21111;
  --thardbox1: #edebfd;
  --thardbox2: #f8f2e6;
  --thardbox3: #e8f2ed;
  --thardbox4: #f6e8e8;
  --tharbg1: #f6f5fe;
  --tharbg2: #fffbf2;
  --tharbg3: #f2f7f5;
  --tharbg4: #faf3f3;
  --tharbor1: #eceafe;
  --tharbor2: #fff7e6;
  --tharbor3: #e6efea;
  --tharbor4: #f6e7e7;
  --casedifrent: #452ff4;
  --suppitems: #fff;
  --subborder: #eceafe;
  --accrborder: #f5f6f7;
  --businessbg: #f8f6f2;
  --busnesicon: #f0ede6;
  --busnesborder: #c2b596;
  --nbg1: #f6f5fe;
  --cloudicon: #412d00;
  --brighterbg: #f6f5fe;
  --fivewbg1: #f6f5fe;
  --fivewbg2: #f2f7f5;
  --fivewbg3: #f8f6f2;
  --fivewbg4: #faf3f3;
  --fivewborder1: #3121ab20;
  --fivewborder2: #00402224;
  --fivewborder3: #4b340024;
  --fivewborder4: #710c0c25;
  --fivewbgbox1: #edebfd;
  --fivewbgbox2: #e6efeb;
  --fivewbgbox3: #f1ede6;
  --fivewbgbox4: #f5e8e8;
  --fiveicon1bor: #b3aafa;
  --fiveicon2bor: #96bcaa;
  --fiveicon3bor: #c2b596;
  --fiveicon4bor: #d99d9d;
  --tquotebor: #eceafe;
  --pinfotext1: #3021ab;
  --pinfotext2: #005c30;
  --pinfotext3: #a21111;
  --pinfotext4: #6b4a00;
  --oddbg: #f6f5fe;
  --cswitch: #eceafe;
  --save30: #a21111;
  --com: #243757;
  --progra1: #452ff4;
  --progra2: #005c30;
  --progra3: #a21111;
  --prostock: #eceafe;
  --prostock2: #e6efea;
  --prostock3: #f6e7e7;
  --prighterborder: #c2c7d0;
  --prightercolor: #243757;
  --achackcion: #452ff4;
  --machinelearning: #fff;
  --placeinput: #42526d;
  --inputborder: #e2defd;
  --inputbg: #f4f2fe;
  --helpbox: #f6f5fe;
  --helpboxbor: #dbd7fd;
  --helpicon: #fbfaff;
  --helpicon2: #fbfaff;
  --helpicon3: #fbfaff;
  --helpiconborder: #e0dcfd;
  --helpiconborder2: #e0dcfd;
  --helpiconborder3: #e0dcfd;
  --helpiconi: #452ff4;
  --contactinptbg: #f6f5fe;
  --contactinptbor: #e4e1fd;
  --place2: #42526d;
  --place2bor: #e4e1fd;
  --repbg: #ffffff;
  --comments: #f6f5fe;
  --shopbg: #fff;
  --shopthumbbg: #f5f4fe;
  --filterborder: #dbd7fdc9;
  --smalllux: #e7e4fd;
  --checkoutborder: #8993a4;
  --minusborder: #c4bdfb;
  --offborder: #dcd8fd;
  --offbg: #edebfe;
  --offtext: #354764;
  --tableheadborde: #eceafe;
  --cartnewborde: #eceafe;
  --checkbor1: #eceafe;
  --payborder: #354764;
  --succborder: #e3e0fd;
  --idborder: #ebe9fd;
  --blockbody: #fff;
  --boxborderpro: #cdc3aa;
  --boxtwoborderpro: #96bcaa;
  --accorlight: #f5f6f7;
  --accorlightbg: #eceafe;
  --removecross: #452ff4;
  --cartbgbox1: #ffffff;
  --laptopbox: #eceafe;
  --priicecustomborder: #ebedf0;
  --paymentfull: #ffffff;
  --paymentfullbody: #f6f5fe;
}

//not allowed down code

// @TODO: edit
// .dark-theme {
.dark {
  --body: #162557;
  --themetext: #f5f6f7;
  --paymentfull: #162557;
  --paymentfullbody: #253167;
  --bgsection: #08162e;
  --whitebgdark: #0b1d3d;
  --darkbg: #f6f5ff;
  --checkoutborder: #3f4784;
  --switch: #2b292a;
  --repbg: #253167;
  --priicecustomborder: #354764;
  --laptopbox: #343e78;
  --cartbgbox1: #253167;
  --countright: #3021ab;
  --box1: #141b56;
  --removecross: #ed5050;
  --shopbg: #162557;
  --shopthumbbg: #27326f;
  --placeinput: #ebedf0;
  --inputborder: #333d78;
  --minusborder: #3a417f;
  --quitborder: #595ca1;
  --quitcolor: #a89efa;
  --quitibg: #454c8b;
  --quitboxbg: #2c376f;
  --cartnewborde: #333d78;
  --inputbg: #162557;
  --comments: #162557;
  --box2: #133f43;
  --accorlight: #1a385d;
  --accorlightbg: #162557;
  --smalllux: #404784;
  --filterborder: #1b2667;
  --helpbox: #162557;
  --helpboxbor: #1d266f;
  --robit: #41e295;
  --box3: #d9d9d9;
  --inbox1: #3021ab;
  --boxborder: #b3aafa;
  --planbuttonborder: #96bcaa;
  --priceborder: #ebedf0;
  --inbox2: #288a5b;
  --inbox4: #3b352b;
  --inbox3: #9c6b00;
  --coutbg1: #0e1e47;
  --coutbg2: #0a213c;
  --coutbg3: #121d3b;
  --coutbg4: #0f203a;
  --coubor1: #d99d9d;
  --pribox1: #142058;
  --pribox2: #09263b;
  --pribox3: #221b36;
  --priboxicon: #f6e7e7;
  --pribox4: #28162c;
  --pricloud4: #630a0a;
  --soc1: #172062;
  --soc2: #09293b;
  --soc3: #1e2631;
  --soc4: #291a34;
  --socbor1: #2e27ab;
  --socbor2: #044235;
  --socbor3: #453818;
  --socbor4: #651523;
  --thardbox1: #452ff4;
  --thardbox2: #6b4900;
  --thardbox3: #005c30;
  --thardbox4: #a21111;
  --tharbg1: #142058;
  --tharbg2: #232c37;
  --tharbg3: #09263b;
  --tharbg4: #221b36;
  --tharbor1: #3021ab;
  --tharbor2: #4b3400;
  --tharbor3: #004022;
  --tharbor4: #630a0a;
  --fwork: #0d1e46;
  --casedifrent: #41e295;
  --suppitems: #162557;
  --subborder: #322bb5;
  --businessbg: #1e2631;
  --busnesicon: #6b4900;
  --busnesborder: #84692b;
  --brighterbg: #162557;
  --fivewbg1: #172162;
  --fivewbg2: #082d3a;
  --fivewbg3: #23282e;
  --fivewbg4: #311a32;
  --fivewbg1bor: #6552f6;
  --fivewbg2bor: #2b7853;
  --fivewbg3bor: #84692b;
  --fivewbg4bor: #c97575;
  --fivewborder1: #3021ab;
  --fivewborder2: #004022;
  --fivewborder3: #4b3400;
  --fivewborder4: #710c0c;
  --fivewbgbox1: #452ff4;
  --fivewbgbox2: #005c30;
  --fivewbgbox3: #6b4900;
  --fivewbgbox4: #a21111;
  --fiveicon1bor: #6552f6;
  --fiveicon2bor: #2b7853;
  --fiveicon3bor: #84692b;
  --fiveicon4bor: #c97575;
  --tquotebor: #3b4380;
  --oddbg: #142058;
  --cswitch: #133b4a;
  --save30: #edcb50;
  --com: #fff;
  --progra1: #b7aefb;
  --progra2: #41e295;
  --progra3: #d99d9d;
  --prighterborder: #a89efa;
  --prightercolor: #f6f5ff;
  --achackcion: #41e295;
  --machinelearning: #162557;
  --contactinptbg: #253167;
  --contactinptbor: #3f4784;
  --helpicon: #452ff4;
  --helpicon2: #005c30;
  --helpicon3: #6b4900;
  --helpiconborder: #452ff4;
  --helpiconborder2: #005c30;
  --helpiconborder3: #6b4900;
  --helpiconi: #fff;
  --priceborder: #ebedf0;
  --place2: #e4e1fd;
  --place2bor: #3f4784;
  --offborder: #353c67;
  --offbg: #182442;
  --offtext: #fff;
  --checkbor1: #3f4784;
  --payborder: #5f62a8;
  --dotsave: #5f62a8;
  --succborder: #162557;
  --idborder: #323c76;
  --blockbody: #172162;
  --boxborderpro: #9c6b00;
  --boxtwoborderpro: #005c30;
}
