@mixin breakpoint($point) {
    @if $point==xs {
        @media (min-width: 500px) {
            @content ;
        }
    }
    @else if $point==max-xs {
        @media (max-width: 500px) {
            @content ;
        }
    }
    @if $point==sm {
        @media (min-width: 576px) {
            @content ;
        }
    }
    @else if $point==max-sm {
        @media (max-width: 575px) {
            @content ;
        }
    }
    @else if $point==md {
        @media (min-width: 768px) {
            @content ;
        }
    }
    @else if $point==max-md {
        @media (max-width: 767px) {
            @content ;
        }
    }
    @else if $point==lg {
        @media (min-width: 992px) {
            @content ;
        }
    }
    @else if $point==max-lg {
        @media (max-width: 991px) {
            @content ;
        }
    }
    @else if $point==xl {
        @media (min-width: 1200px) {
            @content ;
        }
    }
    @else if $point==max-xl {
        @media (max-width: 1199px) {
            @content ;
        }
    }
    @else if $point==xxl {
        @media (min-width: 1400px) {
            @content ;
        }
    }
    @else if $point==max-xxl {
        @media (max-width: 1399px) {
            @content ;
        }
    }
}

@mixin justify {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@mixin wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
@mixin flex {
	display: flex;
	align-items: center;
}
@mixin bordert {
	borrder-top: 1px solid #fff;
}
@mixin borderb {
	borrder-bottom: 1px solid var(--border-color);
}
@mixin radius {
	width: 38px;
	height: 38px;
	line-height: 38px;
	text-align: center;
	border-radius: 50%;
	background: var(--icon-bg);
}

@mixin imgw {
	width: 100%;
	height: 100%;
}
@mixin gradian-bg{
	background: linear-gradient(80.92deg, #00A182 -7.62%, #81CD34 105.55%);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}
@mixin gradian-border{
	background: linear-gradient(80.92deg, #81CD34 -7.62%, #00A182 105.55%);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}
@mixin gradian-50{
	background: linear-gradient(80.92deg, #00A182 -7.62%, #81CD34 105.55%);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
}
@mixin gradian-5{
	background: linear-gradient(80.92deg, #00A182 -7.62%, #81CD34 105.55%);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
}
@mixin gradian-hover{
	background: linear-gradient(80.92deg, #81CD34 -7.62%, #00A182 105.55%);
}
@mixin gradian-text{
	background: linear-gradient(80.92deg, #81CD34 -7.62%, #00A182 105.55%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
@mixin gradian-text-hover{
	background: linear-gradient(80.92deg, #00A182 -7.62%, #81CD34 105.55%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

