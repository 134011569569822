//Banner Eelements
@keyframes zmin{
    0%{
        transform: scale(1.1);
    }
    50%{
        transform: scale(1.4);
    }
    100%{
        transform: scale(1.9);
        opacity: 0;
    }
}
@keyframes zmin2{
    0%{
        transform: scale(2.1);
    }
    100%{
        transform: scale(0.1);
        opacity: 0;
    }
}
@keyframes zmin3{
    0%{
        transform: translateY(10px);
    }
    20%{
        transform: translateX(10px);
    }
    50%{
        transform: translateX(-10px);
    }
    100%{
        transform: translateY(-10px);
    }
}
@keyframes zminopa{
    50%{
        opacity: 1.5;
        transform: translateX(-10px);
    }
    80%{
        opacity: 1.1;
        transform: scale(0.8);
    }
}
@keyframes cirsle{
    0%{
        transform: rotate(180deg);
    }
}
@keyframes cirsmoth {
    0%{
        transform: translateX(0px);
    }
    50%{
        transform: translateX(-5px);
    }
}
@keyframes cir360{
    0%{
        transform: rotate(360deg);
    }
}
@keyframes smothx{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(10px);
    }
}
@keyframes skews{
    50%{
        transform:  translateX(10px);
    }
}
@keyframes somthted{
    50%{
        transform: translateX(-20px);
    }
}
@keyframes airtifi {
    50%{
        transform: translateX(-10px) skew(-5deg);
    }
}
@keyframes rlrl {
    50%{
        transform: translate(-15px);
    }
}
@keyframes workdown {
    50%{
        transform: translateY(30px);
    }
}
@keyframes bns1 {
    50%{
        transform: rotate(-2deg);
    }
    80%{
        transform: translateX(5px);
    }
}
//Banner Eelements