//Preloader Start//
.preloader__wrap {
  position: fixed;
  background: rgba(0, 0, 0, 0.871);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .preloader__box {
    .robot {
      width: 150px;
      animation: smothrobot 2s linear infinite;
      img {
        width: 100%;
      }
    }
  }
}
@keyframes preload {
  50% {
    bottom: 100%;
    right: 100%;
  }
  50% {
    opacity: 0;
  }
}
@keyframes preload2 {
  50% {
    top: 100%;
    left: 100%;
  }
  50% {
    opacity: 0;
  }
}
@keyframes preload3 {
  50% {
    right: 100%;
    top: 100%;
  }
  50% {
    opacity: 0;
  }
}
@keyframes preload4 {
  50% {
    left: 100%;
    bottom: 100%;
  }
  50% {
    opacity: 0;
  }
}
@keyframes smothrobot {
  50% {
    transform: skewX(10deg);
  }
}
//Preloader End//

//Cursor Here//
.cursor {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
  background: transparent;
  pointer-events: none;
  z-index: 111;
  border: 2px solid var(--base);
  transition: all 0.5s ease-out;
  animation: moveCursor1 0.5s infinite alternate;
  .dots {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    background: var(--success);
    border-radius: 50%;
  }
  @include breakpoint(max-lg) {
    display: none;
  }
}
.expand {
  background: transparent;
  animation: moveCursor2 0.4s forwards;
  border: 1px solid var(--success);
}
@keyframes moveCursor1 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.8);
  }
}
@keyframes moveCursor2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
//Cursor End//

//About Start//
.about__section {
  position: relative;
  .about__shape1 {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 330px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      opacity: 0.3;
    }
    @include breakpoint(max-xs) {
      opacity: 0.1;
    }
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.3;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 100px;
    right: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.3;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .light__element1 {
    position: absolute;
    top: 129px;
    left: 174px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element2 {
    position: absolute;
    bottom: 110px;
    left: 309px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element3 {
    position: absolute;
    top: 250px;
    left: 42%;
    transform: translate(-50%);
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element4 {
    position: absolute;
    top: 109px;
    right: 153px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element5 {
    position: absolute;
    bottom: 100px;
    right: 20%;
    animation: zminopa 2s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
}
.about__thumb {
  width: 100%;
  position: relative;
  z-index: 1;
  img {
    width: 100%;
    animation: bns1 10s linear infinite;
  }
  .brain__element {
    position: absolute;
    left: -250px;
    top: 0;
    max-width: 280px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xl) {
      max-width: 180px;
      left: -150px;
    }
    @include breakpoint(max-lg) {
      left: -180px;
    }
    @include breakpoint(max-sm) {
      max-width: 120px;
      left: -70px;
    }
    @include breakpoint(max-xs) {
      max-width: 80px;
      left: 0;
    }
  }
  @include breakpoint(max-sm) {
    max-width: 340px;
    margin: 20px auto;
  }
}
.about__content {
  position: relative;
  z-index: 1;
  .section__header {
    margin-bottom: 2rem;
  }
  .about__chack {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 0;
    margin-bottom: 40px;
    @include breakpoint(max-xs) {
      gap: 24px 15px;
    }
    li {
      width: calc(100% / 2);
      display: flex;
      gap: 10px;
      @include breakpoint(max-xs) {
        width: 100%;
      }
      span {
        color: var(--themetext);
        font-size: 1rem;
        font-weight: 500;
      }
      .icon {
        i {
          line-height: 28px;
          color: var(--achackcion);
          font-size: 1.3rem;
        }
      }
    }
  }
  .cmn--btn {
    color: var(--white);
    &:hover {
      span {
        color: var(--white);
      }
    }
  }
}
//About Two
.about__section__two {
  position: relative;
  overflow: hidden;
  &::after,
  &::before {
    display: none;
  }
  .ball3d {
    position: absolute;
    bottom: 110px;
    right: 300px;
    @include breakpoint(max-xl) {
      top: 120px;
      left: 120px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .banner3__two {
    position: absolute;
    top: 140px;
    right: 22%;
    width: 30px;
    animation: cir360 5s linear infinite;
    @include breakpoint(max-xxl) {
      left: 20px;
      top: 30px;
    }
    img {
      width: 100%;
    }
  }
  .banner__blump {
    position: absolute;
    top: 50px;
    right: 70px;
    width: 50px;
    animation: cir360 10s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      top: 10px;
      right: 40px;
    }
  }
  .banner__nulldimond {
    position: absolute;
    right: 55%;
    top: 120px;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .about__thumb {
    width: initial;
    max-width: 800px;
    position: absolute;
    left: 0;
    bottom: 0;
    @include breakpoint(max-xxl) {
      max-width: 600px;
    }
    @include breakpoint(max-xl) {
      max-width: 500px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
    img {
      width: initial;
      max-width: 100%;
    }
  }
}
.progress__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  @include breakpoint(max-xs) {
    margin-bottom: 20px;
  }
  .pro__items {
    padding: 15px 24px 15px 0;
    width: calc(100% / 2);
    .pro__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;
      .title {
        font-size: 14px;
        color: var(--themetext);
        font-weight: 500;
      }
      .point {
        font-size: 14px;
        color: var(--themetext);
        font-weight: 500;
      }
    }
    @include breakpoint(max-xs) {
      width: 100%;
      padding: 11px 24px 11px 0;
      .pro__head {
        margin-bottom: 12px;
      }
    }
  }
  .progress {
    background: #eceafe;
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    display: flex;
    height: 6px;
    width: 100%;
  }

  .progress-value {
    animation: load 3s normal forwards;
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: var(--base);
    height: 6px;
    width: 0;
  }

  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: 68%;
    }
  }
}
//About Four Here//
.about__section__four {
  position: relative;
  &::before {
    top: 0;
  }
  .empower__element1 {
    position: absolute;
    top: 30px;
    left: 42%;
    animation: rlrl 5s linear infinite;
    @include breakpoint(max-lg) {
      left: 15px;
      top: 10px;
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .empower__lockyello {
    position: absolute;
    bottom: 60px;
    left: 240px;
    @include breakpoint(max-lg) {
      bottom: 20px;
    }
  }
  .empower__blump {
    position: absolute;
    top: 120px;
    right: 100px;
    @include breakpoint(max-xxl) {
      top: 40px;
      right: 40px;
    }
    @include breakpoint(max-xl) {
      top: 15px;
      right: 15px;
    }
    @include breakpoint(max-md) {
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .empower__blump2 {
    position: absolute;
    bottom: 100px;
    right: 30%;
    width: 30px;
    img {
      width: 100%;
    }
  }
  .about__thumb {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 810px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      max-width: 600px;
    }
    @include breakpoint(max-xl) {
      max-width: 500px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .about__content {
    .empower__list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      li {
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        background: var(--white);
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 10px 20px 12px;
        width: calc(95% / 2);
        @include breakpoint(max-xs) {
          width: 100%;
        }
        span {
          font-size: 16px;
          font-weight: 500;
          color: var(--themetext);
        }
        .icon {
          margin-top: 10px;
          i {
            color: var(--base);
            font-size: 20px;
          }
        }
      }
    }
  }
}
//About Five Here//
.about__section__five {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: -50px;
    left: -100px;
    width: 300px;
    height: 300px;
    content: "";
    background: #ed5050;
    opacity: 0.5;
    filter: blur(200px);
  }
  &::after {
    position: absolute;
    bottom: -200px;
    right: -200px;
    width: 300px;
    height: 300px;
    content: "";
    background: #452ff4;
    opacity: 0.3;
    filter: blur(200px);
  }
  .bulp__light {
    position: absolute;
    top: 90px;
    left: 50px;
    @include breakpoint(max-lg) {
      width: 60px;
      top: 30px;
      left: 30px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .threed__ball {
    position: absolute;
    left: 200px;
    bottom: 160px;
    opacity: 0.5;
    animation: cir360 20s linear infinite;
    @include breakpoint(max-md) {
      left: 100px;
    }
    @include breakpoint(max-sm) {
      left: 60px;
    }
    @include breakpoint(max-xs) {
      bottom: 10px;
      left: 10px;
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .global__ball {
    position: absolute;
    top: 70px;
    right: 70px;
    opacity: 0.4;
    animation: cir360 20s linear infinite;
    @include breakpoint(max-xxl) {
      width: 60px;
      top: 30px;
      right: 30px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-md) {
      width: 40px;
      top: 10px;
      right: 10px;
    }
  }
  .null__five {
    position: absolute;
    bottom: 80px;
    right: 22%;
    animation: cir360 15s linear infinite;
    opacity: 0.5;
    @include breakpoint(max-lg) {
      left: 200px;
      animation: none;
      bottom: 500px;
    }
    @include breakpoint(max-md) {
      bottom: 300px;
      left: 100px;
    }
    @include breakpoint(max-xs) {
      display: none;
    }
  }
  .about__content {
    position: relative;
    z-index: 1;
    .about__chack__five {
      li {
        background: var(--pribox1);
        border-radius: 5px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        gap: 10px 16px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .content {
          .texthead {
            font-size: 1rem;
            font-weight: 600;
            color: var(--themetext);
            display: block;
            margin-bottom: 0px;
          }
          span {
            color: var(--themetext);
            font-size: 0.8rem;
          }
        }
        .icon {
          margin-top: 7px;
          i {
            font-size: 1.3rem;
            color: var(--casedifrent);
          }
        }
      }
    }
  }
}
//About End//
@media (min-width: 1400px) and (max-width: 1600px) {
  //About Two
  .about__section__two {
    .about__thumb {
      max-width: 700px;
    }
  }
  .testimonial__thumb__two {
    max-width: 700px;
    img {
      width: 100%;
    }
  }
  //About Four Here//
  .about__section__four {
    position: relative;
    .about__thumb {
      max-width: 710px;
      img {
        width: 100%;
      }
    }
  }
  //About End//

  //faq section Four Here//
  .faq__section__four {
    position: relative;
    overflow: hidden;
    .accordion__thumb__four {
      width: 700px;
      img {
        width: 100%;
      }
    }
  }
}
//Include Start//
.includeai__section {
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    top: -150px;
    left: -150px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: -160px;
    right: -160px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.6;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .include__elements {
    position: absolute;
    left: 42px;
    bottom: 42px;
    @include breakpoint(max-xxl) {
      left: 0;
      bottom: 0;
    }
  }
  .include__elements3d {
    position: absolute;
    top: 87px;
    left: 200px;
    animation: cir360 15s linear infinite;
    @include breakpoint(max-xxl) {
      left: 40px;
      top: 40px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .include__blumb {
    position: absolute;
    right: 110px;
    bottom: 70px;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-xxl) {
      right: 40px;
      bottom: 40px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
}
.include__cheack {
  position: relative;
  .threeroune {
    position: absolute;
    right: 150px;
    top: 60px;
    width: 20px;
    animation: cir360 5s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      right: 20px;
    }
  }
  .include__null {
    position: absolute;
    bottom: 0;
    right: 100px;
    animation: cir360 12s linear infinite;

    @include breakpoint(max-lg) {
      right: 0;
      bottom: 50px;
    }
  }
  .about__chack {
    li {
      .icon {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background: #eceafe;
        text-align: center;
        line-height: 50px;
        i {
          color: #2a1d95;
        }
      }
      span {
        line-height: 32px;
        color: var(--themetext);
      }
      &:nth-child(2) {
        .icon {
          background: #f6e7e7;
          i {
            color: #630a0a;
          }
        }
      }
      &:nth-child(3) {
        .icon {
          background: #e6efea;
          i {
            color: #00381d;
          }
        }
      }
      &:nth-child(4) {
        .icon {
          background: #f0ede6;
          i {
            color: #412d00;
          }
        }
      }
    }
  }
}
.include__boxes {
  position: relative;
  z-index: 1;
  @include breakpoint(max-lg) {
    margin-top: 35px;
  }
  .inclue__box {
    border: 1px solid var(--boxborder);
    background: var(--box1);
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 20px 24px;
    gap: 15px 24px;
    transition: all 0.4s;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    &:nth-child(2) {
      background: var(--box2);
      border: 1px solid var(--planbuttonborder);
      .icnos {
        border: 1px solid var(--planbuttonborder);
        background: var(--inbox2);
      }
      @include breakpoint(xl) {
        transform: translateX(50px);
      }
    }
    &:nth-child(3) {
      background: var(--inbox4);
      border: 1px solid var(--inborder);
      .icnos {
        border: 1px solid var(--inborder);
        background: var(--inbox3);
      }
    }
    .icnos {
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 50px;
      background: var(--inbox1);
      border: 1px solid var(--boxborder);
      border-radius: 5px;
    }
    .content {
      width: calc(100% - 60px);
      h4 {
        color: var(--themetext);
        margin-bottom: 8px;
        a {
          color: var(--themetext);
        }
      }
      p {
        color: var(--countptext);
        font-size: 16px;
      }
    }
    @include breakpoint(max-xs) {
      padding: 15px 15px;
      gap: 0 15px;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      .content {
        h4 {
          font-size: 1.2em;
          margin-bottom: 5px;
        }
      }
    }
    &:hover {
      box-shadow: 1px 1px 2px 1px var(--base);
    }
  }
}
.include__aicloud__wrapper {
  .include__boxes {
    .inclue__box {
      &:nth-child(2) {
        @include breakpoint(xl) {
          transform: translateX(0px) !important;
        }
      }
    }
  }
}
//Include Start//
.include__ml__section {
  position: relative;
  overflow: hidden;
  .include__elements {
    width: 80px;
    img {
      width: 100%;
    }
    @include breakpoint(max-sm) {
      width: 30px;
    }
  }
  .include__spring {
    position: absolute;
    top: 60px;
    right: 60px;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-xxl) {
      top: 30px;
    }
    @include breakpoint(max-sm) {
      width: 40px;
      top: 20px;
      right: 20px;
      img {
        width: 100%;
      }
    }
  }
  .redlight1 {
    position: absolute;
    right: 300px;
    bottom: 60px;
  }
  .redlight2 {
    position: absolute;
    left: 220px;
    top: 60px;
    @include breakpoint(max-md) {
      left: 20px;
      top: 20px;
    }
  }
  .include__elements {
    @include breakpoint(max-lg) {
      width: 40px;
      bottom: 10px;
      left: 10px;
    }
  }
}
.include__grid__boxes {
  .inclue__box {
    display: grid;
    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:nth-child(2) {
      margin-left: 0;
    }
    .icnos {
      @include breakpoint(max-xs) {
        margin-bottom: 15px;
      }
    }
    .content {
      width: 100%;
      h4 {
        a {
          color: var(--themetext);
        }
      }
      @include breakpoint(max-xxl) {
        h4 {
          font-size: 1.2em;
        }
      }
      @include breakpoint(max-xl) {
        h4 {
          font-size: 1rem;
        }
      }
      @include breakpoint(max-lg) {
        h4 {
          font-size: 1.1em;
        }
      }
    }
  }
  .include__box__grid1 {
    background: var(--box2) !important;
    border: 1px solid var(--planbuttonborder) !important;
    .icnos {
      border: 1px solid var(--planbuttonborder) !important;
      background: var(--inbox2) !important;
    }
  }
  .include__box__grid2 {
    background: var(--inbox4) !important;
    border: 1px solid var(--inborder) !important;
    .icnos {
      border: 1px solid var(--inborder) !important;
      background: var(--inbox3) !important;
    }
  }
  .include__box__grid3 {
    background: var(--pribox4) !important;
    border: 1px solid #d99d9d !important;
    .icnos {
      border: 1px solid var(--inborder) !important;
      background: var(--pricloud4) !important;
      i {
        color: var(--priboxcolor4) !important;
      }
    }
  }
}
.custom__70 {
  margin-top: 70px;
  @include breakpoint(max-sm) {
    margin-top: 20px;
  }
}
//Include four Here//
.include__benefits {
  position: relative;
  .bene__element1 {
    position: absolute;
    top: 85px;
    left: 105px;
    animation: rlrl 5s linear infinite;
    @include breakpoint(max-lg) {
      top: 10px;
      left: 25px;
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .bene__blump {
    position: absolute;
    top: 100px;
    right: 100px;
    @include breakpoint(max-lg) {
      width: 30px;
      top: 15px;
      right: 15px;
      img {
        width: 100%;
      }
    }
  }
  .bene__blump2 {
    position: absolute;
    right: 196px;
    bottom: 80px;
    width: 30px;
    img {
      width: 100%;
    }
    @include breakpoint(max-lg) {
      left: 20px;
    }
  }
  .bene__lockyello {
    position: absolute;
    left: 200px;
    bottom: 80px;
    @include breakpoint(max-lg) {
      bottom: 30px;
    }
  }
}
.include__benefits__wrap {
  .owl-nav {
    margin-top: 35px;
    @include breakpoint(max-sm) {
      margin-top: 20px;
    }
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      // @TODO: add background-color
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      // @TODO: add background-color
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 29px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
  }
}
.inclue__benefits__box {
  border: 1px solid var(--boxborder);
  background: var(--box1);
  border-radius: 5px;
  padding: 20px 24px;
  gap: 15px 24px;
  text-align: center;
  .icnos {
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 50px;
    background: var(--inbox1);
    border: 1px solid var(--boxborder);
    border-radius: 5px;
    margin: 0 auto 20px;
    padding: 7px;
  }
  .content {
    text-align: center;
    h4 {
      color: var(--themetext);
      margin-bottom: 8px;
    }
    p {
      color: var(--themetext);
      font-size: 16px;
      margin-bottom: 10px;
    }
    .benefits__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      span {
        color: var(--robit);
        font-weight: 600;
        font-size: 1rem;
      }
      .icon {
        margin-top: 7px;
        i {
          color: var(--robit);
        }
      }
    }
  }
}
.benefits__box2 {
  background: var(--box2);
  border: 1px solid var(--planbuttonborder);
  .icnos {
    border: 1px solid var(--planbuttonborder);
    background: var(--inbox2);
  }
}
.benefits__box3 {
  background: var(--inbox4);
  border: 1px solid var(--inborder);
  .icnos {
    border: 1px solid var(--inborder);
    background: var(--inbox3);
  }
}
//Include End//

//pagination Here//
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  li {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #452ff4;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;
    .icon {
      margin-top: 5px;
    }
    a {
      font-size: 16px;
      font-weight: 600;
      color: var(--themetext);
      i {
        color: var(--themetext);
        font-size: 19px;
        transition: all 0.4s;
      }
    }
    &:hover {
      background: #452ff4;
      a {
        color: var(--white);
        i {
          color: var(--white);
        }
      }
    }
  }
}
//pagination End//

//makeservice Here//
.make__service__section {
  position: relative;
  .airobot {
    position: absolute;
    top: 35px;
    left: 35px;
    animation: rlrl 10s linear infinite;
    @include breakpoint(max-xxl) {
      width: 110px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-sm) {
      width: 80px;
      top: 10px;
      left: 10px;
    }
  }
  .checkai {
    position: absolute;
    bottom: 35px;
    right: 35px;
    z-index: 1;
    @include breakpoint(max-xxl) {
      width: 80px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-sm) {
      width: 55px;
    }
  }
  .textgreen__light {
    position: absolute;
    top: 185px;
    right: 30%;
    @include breakpoint(max-xxl) {
      top: 50px;
      right: 50px;
    }
  }
  .light__elegr {
    bottom: 60px;
    left: 20%;
    position: absolute;
  }
  .light__element2gr {
    position: absolute;
    top: 60px;
    left: 40%;
    @include breakpoint(max-xs) {
      display: none;
    }
  }
}
.make__service {
  @include breakpoint(max-lg) {
    margin-bottom: 35px;
  }
  .counting__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 50px;
    justify-content: center;
    @include breakpoint(max-sm) {
      gap: 20px;
    }
    .counter__items {
      margin: 0 auto;
      text-align: center;
      .counter__content {
        .cont {
          justify-content: center;
          margin-bottom: 4px;
          .odometer {
            font-size: 2.5rem;
            line-height: 120%;
            font-weight: 600;
            color: var(--themetext);
            padding: 0;
          }
          .plus__icon {
            font-size: 2.5rem;
            font-weight: 600;
            color: var(--themetext);
            line-height: 120%;
          }
        }
        @include breakpoint(max-sm) {
          .cont {
            .odometer {
              font-size: 2rem;
            }
            .plus__icon {
              font-size: 2rem;
            }
          }
          p {
            font-size: 0.9rem;
          }
        }
      }
      .counter__content__green {
        .cont {
          .odometer {
            color: var(--basetwo) !important;
          }
          .plus__icon {
            color: var(--basetwo) !important;
          }
        }
      }
      .counter__content__red {
        .cont {
          .odometer {
            color: var(--themeneed) !important;
          }
          .plus__icon {
            color: var(--themeneed) !important;
          }
        }
      }
      &:nth-child(1) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--base);
            }
            .plus__icon {
              color: var(--base);
            }
          }
        }
      }
      &:nth-child(2) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--basetwo);
            }
            .plus__icon {
              color: var(--basetwo);
            }
          }
        }
      }
      &:nth-child(3) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--basethree);
            }
            .plus__icon {
              color: var(--basethree);
            }
          }
        }
      }
      p {
        color: var(--countptext);
        font-size: 1rem;
        font-weight: 500;
        display: block;
      }
    }
    .counting__middle {
      .counter__items {
        &:not(:last-child) {
          margin-bottom: 70px;
        }
      }
    }
  }
}
//makeservice End//

//Highlight Here//
.hightlight__section__five {
  position: relative;
  .j__shape {
    position: absolute;
    bottom: 100px;
    right: 100px;
    animation: cir360 15s linear infinite;
    @include breakpoint(max-xxl) {
      bottom: 30px;
      right: 30px;
      width: 80px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-sm) {
      width: 40px;
      bottom: 30px;
    }
  }
  .j__ball1 {
    position: absolute;
    bottom: 180px;
    left: 120px;
    width: 40px;
    img {
      width: 100%;
    }
    animation: cir360 15s linear infinite;
    @include breakpoint(max-xxl) {
      bottom: 30px;
      left: 30px;
    }
    @include breakpoint(max-md) {
      left: 20px;
      bottom: 10px;
    }
  }
  .j__ball2 {
    position: absolute;
    top: 15%;
    right: 20%;
    width: 40px;
    img {
      width: 100%;
    }
    animation: cir360 15s linear infinite;
    @include breakpoint(max-xl) {
      right: 5%;
      top: 5%;
    }
    @include breakpoint(max-md) {
      right: 2%;
      top: 1%;
    }
  }
}
.highlight__left__wrap.include__boxes {
  @include breakpoint(max-lg) {
    margin-top: 0;
  }
}
.highlight__right__wrap.include__boxes {
  @include breakpoint(max-lg) {
    margin-top: 0;
  }
}
.highlight__left__wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .inclue__box {
    text-align: right;
    position: relative;
    border: none !important;
    .icnos {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: var(--cloudicon);
        font-size: 2.5rem;
      }
    }
    &:nth-child(1) {
      transform: translateY(-40px);
      &::before {
        position: absolute;
        bottom: -110px;
        right: -60px;
        content: "";
        background: url(../../public/img/efective/an1.png) no-repeat center center;
        background-size: contain;
        width: 100px;
        height: 100px;
        @include breakpoint(max-xl) {
          display: none;
        }
      }
      @include breakpoint(max-lg) {
        transform: translateY(0px);
        &::before {
          display: none;
        }
      }
    }
    &:nth-child(2) {
      transform: translateY(40px);
      &::before {
        position: absolute;
        top: -110px;
        right: -60px;
        content: "";
        background: url(../../public/img/efective/an2.png) no-repeat center center;
        background-size: contain;
        width: 100px;
        height: 100px;
      }

      @include breakpoint(max-lg) {
        transform: translateY(0px);
        margin-top: 25px;
        &::before {
          display: none;
        }
      }
    }
  }
}
.highlight__right__wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .inclue__box {
    border: none !important;
    &:nth-child(1) {
      transform: translateY(60px);
      @include breakpoint(max-xxl) {
        transform: translateY(0px);
      }
      @include breakpoint(max-xl) {
        transform: translateY(-40px);
      }
      &::before {
        position: absolute;
        bottom: -75px;
        left: -60px;
        content: "";
        background: url(../../public/img/efective/an3.png) no-repeat center center;
        background-size: contain;
        width: 100px;
        height: 100px;
      }
      @include breakpoint(max-lg) {
        transform: translateY(0px);
        &::before {
          display: none;
        }
      }
    }
    &:nth-child(2) {
      transform: translateY(-60px);
      @include breakpoint(max-xxl) {
        transform: translateY(0px);
      }
      @include breakpoint(max-xl) {
        transform: translateY(40px);
      }
      &::before {
        position: absolute;
        top: -75px;
        left: -60px;
        content: "";
        background: url(../../public/img/efective/an4.png) no-repeat center center;
        background-size: contain;
        width: 100px;
        height: 100px;
      }
      @include breakpoint(max-lg) {
        transform: translateY(0px);
        margin-top: 25px;
        &::before {
          display: none;
        }
      }
    }
  }
}
.highlight__thumb {
  width: 100%;
  padding: 20px 50px;
  @include breakpoint(max-xl) {
    padding: 10px 10px;
  }
  @include breakpoint(max-lg) {
    padding: 25px 0;
  }
  img {
    width: 100%;
    border-radius: 5px;
  }
}
//Highlight End//

//professional Here//
.professional__section {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: -50px;
    right: -50px;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.3;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .jeb__round {
    position: absolute;
    bottom: 60px;
    left: 80px;
    width: 60px;
    animation: cir360 15s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      left: 10px;
      bottom: 10px;
      width: 40px;
    }
  }
  .threed__round {
    position: absolute;
    top: 90px;
    left: 240px;
    animation: cir360 15s linear infinite;
    width: 40px;
    opacity: 0.5;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      width: 30px;
      left: 20px;
      top: 20px;
    }
  }
  .threed__round2 {
    position: absolute;
    top: 50%;
    right: 120px;
    animation: cir360 15s linear infinite;
    width: 50px;
    img {
      width: 100%;
      opacity: 0.5;
    }
    @include breakpoint(max-xxl) {
      top: 1%;
      right: 20px;
    }
    @include breakpoint(max-xs) {
      display: none;
    }
  }
}
.professional__content {
  position: relative;
  z-index: 1;
  .promap1 {
    position: absolute;
    top: -60px;
    right: -60px;
    z-index: -1;
  }
  @include breakpoint(max-lg) {
    margin-bottom: 30px;
  }
}
.professional__right__content {
  position: relative;
  .promap2 {
    position: absolute;
    left: 0;
    top: -60px;
  }
  .progr__wrap {
    .progressbar {
      position: relative;
      svg {
        @include breakpoint(max-xl) {
          width: 130px;
          height: 130px;
        }
        path {
          fill: var(--progra1);
        }
        .pathtwo {
          fill: var(--prostock);
        }
      }
      .content__box {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        .count {
          display: block;
          color: var(--progra1);
          font-size: 2rem;
          font-weight: 600;
          margin-bottom: 8px;
          @include breakpoint(max-xl) {
            font-size: 1.5rem;
          }
        }

        .text {
          font-size: 1rem;
          font-weight: 500;
          color: var(--com);
          font-size: 0.9rem;
        }
      }
      &:nth-child(2) {
        svg {
          path {
            fill: var(--progra2);
          }
          .pathtwo {
            fill: var(--prostock2);
          }
        }
        .content__box {
          .count {
            color: var(--progra2);
          }
          .text {
            color: var(--com);
          }
        }
      }
      &:nth-child(3) {
        svg {
          path {
            fill: var(--progra3);
          }
          .pathtwo {
            fill: var(--prostock3);
          }
        }
        .content__box {
          .count {
            color: var(--progra3);
          }
          .text {
            color: var(--com);
          }
        }
      }
    }
    @include breakpoint(max-xs) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
//professional End//

//Project Count Here//
.project__count {
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    top: -200px;
    left: -200px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: -200px;
    right: -200px;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
}
.project__count__wrap {
  position: relative;
  .project__map {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%);
    width: 1170px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.project__head {
  position: relative;
  z-index: 1;
  .section__header {
    max-width: 650px;
  }
}
.project__count__items {
  position: relative;
  z-index: 1;
  .icon {
    background: var(--coutbg1);
    width: 48px;
    height: 48px;
    border-radius: 5px;
    line-height: 64px;
    border: 1px solid var(--boxborder);
    text-align: center;
    margin: 0 auto 16px;
    @include breakpoint(max-lg) {
      margin: 0 auto 0px;
    }
    i {
      font-size: 32px;
      color: var(--base);
    }
  }
  .icon2 {
    background: var(--coutbg2);
    border: 1px solid var(--planbuttonborder);
    i {
      color: var(--basetwo);
    }
  }
  .icon3 {
    background: var(--coutbg3);
    border: 1px solid var(--coubor1);
    i {
      color: var(--themeneed);
    }
  }
  .icon4 {
    background: var(--coutbg4);
    border: 1px solid var(--inborder);
    i {
      color: var(--basethree);
    }
  }
  .counter__items {
    text-align: center;
    .counter__content {
      .cont {
        justify-content: center;
        .odometer {
          font-size: 2.5rem;
          margin-bottom: 4px;
          color: var(--base);
          font-weight: 600;
          @include breakpoint(max-lg) {
            margin-bottom: -5px;
          }
        }
        .plus__icon {
          font-size: 2.5rem;
          color: var(--base);
          font-weight: 600;
          margin-top: -6px;
        }
      }
    }
    p {
      font-size: 1rem;
      color: var(--countptext);
    }
  }
  .counter__items2 {
    .counter__content {
      .cont {
        .odometer {
          color: var(--basetwo);
        }
        .plus__icon {
          color: var(--basetwo);
        }
      }
    }
  }
  .counter__items3 {
    .counter__content {
      .cont {
        .odometer {
          color: var(--themeneed);
        }
        .plus__icon {
          color: var(--themeneed);
        }
      }
    }
  }
  .counter__items4 {
    .counter__content {
      .cont {
        .odometer {
          color: var(--basethree);
        }
        .plus__icon {
          color: var(--basethree);
        }
      }
    }
  }
}
.project__qustion {
  position: relative;
  z-index: 1;
  background: rgba(69, 47, 244, 0.05);
  border-radius: 5px;
  border: 1px solid var(--box1);
  padding: 40px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px 10px;
  @include breakpoint(max-sm) {
    padding: 30px 20px;
  }
  h5 {
    color: var(--themetext);
    margin-bottom: 8px;
  }
  p {
    color: var(--themetext);
    font-size: 16px;
  }
}
//Project Count End//

//Service Details Here//
.blog__details__left {
  .machine__learning__box {
    .content {
      padding-right: 20px !important;
      padding-left: 20px !important;
      @include breakpoint(max-sm) {
        padding-right: 10px !important;
        padding-left: 10px !important;
      }
      .headtext {
        color: var(--themetext);
        @include breakpoint(max-lg) {
          font-size: 32px;
          line-height: 45px;
        }
        @include breakpoint(max-md) {
          font-size: 26px;
          line-height: 36px;
        }
        @include breakpoint(max-xs) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 1px;
        }
      }
      .admin__wrap {
        display: flex;
        align-items: center;
        gap: 10px 32px;
        margin-top: 30px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(69, 47, 244, 0.1);
        @include breakpoint(max-xs) {
          flex-wrap: wrap;
        }
        li {
          display: flex;
          align-items: center;
          gap: 8px;
          .icon {
            margin-top: 5px;
            i {
              font-size: 20px;
              color: var(--achackcion);
            }
          }
          span {
            font-size: 18px;
            font-weight: 400;
            color: var(--place2);
          }
        }
      }
      .touch__standard {
        display: flex;
        align-items: center;
        gap: 20px 40px;
        padding: 28px 0;
        .thumb {
          img {
            border-radius: 5px;
          }
          @include breakpoint(max-xs) {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .touch__box {
          .ttext {
            margin-bottom: 20px;
          }
        }
        @include breakpoint(max-xl) {
          gap: 20px 0px;
          flex-wrap: wrap;
          .thumb {
            width: 100%;
          }
        }
      }
      .video__thumb {
        margin: 28px 0;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &::before {
          position: absolute;
          content: "";
          inset: 0;
          width: 100%;
          background: rgba($color: #1c4848, $alpha: 0.4);
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        .video-btn {
          display: block;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: var(--intellicon);
          text-align: center;
          line-height: 70px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 50%;
          transition: all 0.4s;
          i {
            font-size: 29px;
            color: var(--themetext);
          }
        }
      }
      .standard__footer {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid rgba(69, 47, 244, 0.1);
        padding-top: 24px;
        margin-top: 28px;
        @include breakpoint(max-xs) {
          display: grid;
          gap: 15px;
        }
        .standard___btn {
          display: flex;
          align-items: center;
          gap: 5px;
          .icon {
            margin-top: 6px;
            i {
              font-size: 22px;
              color: var(--themetext);
            }
          }
          span {
            font-weight: 600;
            color: var(--themetext);
            font-size: 1rem;
            color: var(--themetext);
          }
        }
        .standard___btn2 {
          display: flex;
          align-items: center;
          gap: 5px;
          .icon {
            margin-top: 6px;
            i {
              font-size: 22px;
              color: var(--achackcion);
            }
          }
          span {
            font-weight: 600;
            color: var(--achackcion);
            font-size: 1rem;
            color: var(--achackcion);
          }
        }
        .social__standard {
          padding-bottom: 0;
          padding-left: 0;
        }
      }
    }
  }
}
.service__details__left {
  .machine__learning__box {
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background: var(--machinelearning);
    padding: 8px;
    .details__thumb {
      margin-bottom: 40px;
      width: 100%;
      img {
        width: 100%;
        border-radius: 5px;
      }
      @include breakpoint(max-md) {
        margin-bottom: 20px;
      }
    }
    .content {
      border-bottom: 1px solid rgba(69, 47, 244, 0.1);
      padding-bottom: 24px;
      margin-bottom: 24px;
      padding-right: 28px;
      padding-left: 28px;
      @include breakpoint(max-md) {
        padding-right: 8px;
        padding-left: 8px;
      }
      .title1 {
        margin-bottom: 20px;
        color: var(--prightercolor);
        @include breakpoint(max-sm) {
          font-size: 32px;
          margin-bottom: 15px;
        }
        @include breakpoint(max-xs) {
          font-size: 28px;
        }
      }
      .text1 {
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        color: var(--prightercolor);
      }
      .more__details__mid {
        padding: 24px 0;
        width: 100%;
        img {
          width: 100%;
          border-radius: 5px;
        }
      }
      .more__details__thumb {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 24px 0;
        .more__details__item {
          width: calc(95% / 2);
          max-width: 100%;
          border-radius: 5px;
          img {
            width: 100%;
            border-radius: 5px;
          }
          @include breakpoint(max-xs) {
            width: calc(100%);
          }
        }
      }
      .machine__listing {
        margin-bottom: 8px;
        li {
          display: flex;
          align-items: center;
          gap: 4px;
          &:not(:last-child) {
            margin-bottom: 4px;
          }
          span {
            font-size: 16px;
            color: var(--prightercolor);
          }
        }
      }
    }
    .describtion__reviews {
      .nav {
        display: flex;
        align-items: center;
        gap: 20px;
        border: none;
        .nav-item {
          border: none;
          .nav-link {
            font-size: 18px;
            font-weight: 500;
            color: var(--themetext);
            border: none;
            padding: 0;
            &.active {
              color: var(--base);
              text-decoration: underline;
              background: transparent;
            }
          }
        }
      }
    }
    .social__standard {
      padding-left: 28px;
      padding-bottom: 28px;
    }
  }
  .write__review__wrap {
    margin-top: 40px;
    .title {
      margin-bottom: 28px;
      color: var(--themetext);
    }
    form {
      input {
        width: 100%;
        background: rgba(69, 47, 244, 0.05);
        border: 1px solid rgba(69, 47, 244, 0.1);
        padding: 12px 24px;
        color: #42526d;
      }
      ::placeholder {
        color: #42526d;
      }
      textarea {
        background: rgba(69, 47, 244, 0.05);
        border: 1px solid rgba(69, 47, 244, 0.1);
        padding: 12px 24px;
        color: #42526d;
        outline: none;
        box-shadow: none;
      }
      .cmn--btn {
        width: 160px;
        border: none;
        outline: none;
        margin-left: 10px;
      }
    }
  }
  @include breakpoint(max-lg) {
    margin-bottom: 35px;
  }
}
.social__standard {
  display: flex;
  align-items: center;
  gap: 5px 12px;
  .social__item {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -4px;
    }
  }
  li {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--soc1);
    border: 1px solid var(--socbor1);
    a {
      display: block;
      i {
        color: var(--base);
        font-size: 20px;
      }
    }
    &:nth-child(2) {
      background: var(--soc2);
      border-color: var(--socbor2);
      a {
        i {
          color: var(--basetwo);
        }
      }
    }
    &:nth-child(3) {
      background: var(--soc3);
      border-color: var(--socbor3);
      a {
        i {
          color: var(--basethree);
        }
      }
    }
    &:nth-child(4) {
      background: var(--soc4);
      border-color: var(--socbor4);
      a {
        i {
          color: var(--themeneed);
        }
      }
    }
  }
}
.describtion__reviews {
  padding: 0px 28px;
  @include breakpoint(max-xs) {
    padding: 0 8px;
  }
}
.reviews__boxes {
  padding: 12px 0;
  display: flex;
  gap: 20px;
  .review-content {
    .name__ratting {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 8px;
      .name {
        color: var(--themetext);
        font-weight: 500;
      }
      .time {
        color: #42526d;
        font-size: 16px;
      }
      .ratting {
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          i {
            color: var(--intellicon);
            font-size: 16px;
          }
        }
      }
      @include breakpoint(max-xs) {
        flex-wrap: wrap;
        gap: 5px 10px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #42526d;
      margin-bottom: 8px;
    }
    .view__btn {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        font-size: 16px;
        font-weight: 600;
        color: var(--base);
      }
      .icon {
        margin-top: 10px;
        i {
          color: var(--base);
          font-size: 20px;
        }
      }
    }
  }
  @include breakpoint(max-xs) {
    gap: 10px;
    flex-wrap: wrap;
  }
}
.describtion__text {
  color: var(--themetext);
  font-size: 16px;
  padding: 10px 10px;
}

//right side bar
.common__item {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: var(--white);
  padding: 24px 24px 24px;
  @include breakpoint(max-xl) {
    padding: 20px 15px;
  }
  @include breakpoint(max-xs) {
    padding: 20px 15px;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  h4 {
    margin-bottom: 20px;
    color: var(--themetext);
  }
  .title {
    border-bottom: 1px solid var(--priceborder);
    padding-bottom: 16px;
    margin-bottom: 20px;
  }
  form {
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    background: rgba(69, 47, 244, 0.05);
    border: 1px solid rgba(69, 47, 244, 0.1);
    input {
      width: 100%;
      border-radius: 5px;
      padding: 12px 20px;
      border: none;
      outline: none;
      background: transparent;
      color: #42526d;
    }
    ::placeholder {
      color: #42526d;
    }
    button {
      margin-right: 10px;
      margin-top: 8px;
      background: none;
      border: none;
      outline: none;
      i {
        color: var(--base);
        font-size: 20px;
      }
    }
  }
  .service__link__wrap {
    a {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 15px 20px;
      border: 1px solid rgba(69, 47, 244, 0.1);
      border-radius: 5px;
      transition: all 0.4s;
      .icon {
        background: rgba(69, 47, 244, 0.05);
        border: 1px solid rgba(69, 47, 244, 0.1);
        border-radius: 5px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font-size: 18px;
        font-weight: 500;
        color: var(--com);
        font-family: "Inter", sans-serif;
        transition: all 0.4s;
        @include breakpoint(max-xs) {
          font-size: 16px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      &:hover {
        background: var(--base);
        .icon {
          background: var(--cswitch);
        }
        span {
          color: var(--white);
        }
      }
    }
  }
  .recent__wrap {
    .recent__items {
      display: flex;
      align-items: center;
      gap: 9px 20px;
      .recent__content {
        width: calc(100% - 150px);
        h6 {
          color: var(--themetext);
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 500;
          font-family: "Inter";
          line-height: 150%;
          @include breakpoint(max-xs) {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
        .date {
          font-size: 16px;
          color: #452ff4;
          @include breakpoint(max-xs) {
            font-size: 14px;
          }
        }
        .recent__btn {
          display: flex;
          align-items: center;
          gap: 5px;
          span {
            color: var(--achackcion);
            font-size: 1rem;
            font-weight: 600;
            line-height: 26px;
          }
          .icon {
            margin-top: 6px;
            i {
              font-size: 18px;
              color: var(--achackcion);
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .thumb {
        display: block;
        width: 150px;
        img {
          width: 100%;
          border-radius: 5px;
        }
      }
      @include breakpoint(max-xl) {
        gap: 9px 12px;
        .thumb {
          width: 100px;
        }
        .recent__content {
          width: calc(100% - 100px);
        }
      }
    }
  }
  .popular__tag {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    li {
      a {
        display: inline-block;
        background: var(--oddbg);
        padding: 12px 20px;
        border: 1px solid var(--place2bor);
        border-radius: 5px;
        color: var(--themetext);
        font-size: 16px;
      }
    }
  }
}
.comments__wrap {
  background: var(--comments);
  padding: 28px 28px;
  border: 1px solid var(--place2bor);
  border-radius: 5px;
  @include breakpoint(max-sm) {
    padding: 20px 15px;
  }
  h3 {
    color: var(--themetext);
    padding-bottom: 40px;
    border-bottom: 1px solid var(--place2bor);
    @include breakpoint(max-sm) {
      font-size: 28px;
      padding-bottom: 22px;
    }
  }
  .reviews__boxes {
    &:not(:last-child) {
      border-bottom: 1px solid var(--place2bor);
    }
    .thumb {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
    .review-content {
      width: calc(100% - 100px);
      @include breakpoint(max-xl) {
        width: calc(100%);
      }
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .time {
        font-size: 16px;
      }
      p {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .liked__wrap {
        display: flex;
        align-items: center;
        gap: 40px;
        li {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            color: var(--robit);
            font-size: 18px;
            font-weight: 400;
          }
          .icon {
            margin-top: 6px;
            i {
              color: var(--robit);
              font-size: 20px;
            }
          }
        }
      }
      .reply__boxes {
        display: flex;
        align-items: center;
        gap: 10px 20px;
        margin-bottom: 40px;
        margin-top: 24px;
        .icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .input__box {
          width: calc(100% - 60px);
          input {
            width: 100%;
            outline: none;
            background: var(--repbg);
            border: 1px solid var(--contactinptbor);
            padding: 15px 20px;
            color: var(--placeinput);
            border-radius: 5px;
          }
          ::placeholder {
            color: var(--placeinput);
          }
        }
      }
    }
  }
}
//Service Details End//

//real world Here//
.real__world {
  position: relative;
  overflow: hidden;
  .include__real {
    position: absolute;
    top: 60px;
    left: 105px;
    @include breakpoint(max-xxl) {
      top: 20px;
      left: 40px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .include__elements {
    position: absolute;
    right: 40px;
    bottom: 40px;
    @include breakpoint(max-xxl) {
      right: 15px;
      bottom: 0;
      width: 80px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-xs) {
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .include__blumb {
    position: absolute;
    bottom: 120px;
    left: 130px;
    animation: cir360 10s linear infinite;
    width: 60px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      bottom: 0;
      left: 10px;
    }
    @include breakpoint(max-xs) {
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
  .include__blumb2 {
    position: absolute;
    top: 140px;
    right: 120px;
    animation: cir360 10s linear infinite;
    width: 60px;
    img {
      width: 100%;
    }
    @include breakpoint(max-lg) {
      top: 20px;
      right: 20px;
    }
    @include breakpoint(max-xs) {
      width: 40px;
      img {
        width: 100%;
      }
    }
  }
}
.realworld__items {
  position: relative;
  z-index: 1;
  padding: 24px 24px 24px;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: var(--white);
  display: flex;
  gap: 24px;
  align-items: center;
  border-right: 4px solid rgba(69, 47, 244, 0.3);
  margin-top: 24px;
  transition: all 0.3s;
  .thumb {
    position: relative;
    margin-top: -54px;
    transition: all 0.3s;
    max-width: 238px;
    &::before {
      position: absolute;
      inset: 0;
      content: "";
      background: #b3aafa;
      opacity: 0.2;
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.4s;
    }
    img {
      width: 100%;
      border-radius: 5px;
    }
    .play__btn {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: var(--intellicon);
      text-align: center;
      line-height: 60px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 0%;
      opacity: 0;
      transition: all 0.4s;
      i {
        font-size: 26px;
        color: #2a1d95;
      }
    }
  }
  .content {
    width: calc(100% - 238px);
    h6 {
      color: var(--basetwo);
      margin-bottom: 8px;
    }
    h4 {
      @include breakpoint(max-lg) {
        font-size: 1.3em;
      }
      a {
        color: var(--countptext);
      }
      margin-bottom: 8px;
    }
    p {
      color: var(--themetext);
      font-size: 16px;
      margin-bottom: 20px;
    }
    .real__btn {
      display: flex;
      gap: 4px;
      span {
        color: var(--base);
        font-size: 1rem;
        font-weight: 600;
      }
      .icon {
        transition: all 0.3s;
        margin-top: 4px;
        i {
          color: var(--base);
          font-size: 18px;
        }
      }
    }
  }
  &:hover {
    .thumb {
      &::before {
        transform: scale(1);
      }
      .play__btn {
        opacity: 1;
        left: 50%;
      }
    }
    border-right: 4px solid var(--base);
    .content {
      h4 {
        a {
          color: var(--base);
        }
      }
      .real__btn {
        .icon {
          padding-left: 10px;
        }
      }
    }
  }
  @include breakpoint(max-xl) {
    flex-wrap: wrap;
    .content {
      width: calc(100%);
    }
  }
}
.case__btn {
  text-align: center;
  padding-top: 40px;
  @include breakpoint(max-xxl) {
    padding-top: 25px;
  }
}
//real world End//

//Feature Start//
.feature__section {
  position: relative;
  overflow: hidden;
  .feature__rocket {
    position: absolute;
    bottom: 60px;
    left: 60px;
    animation: smothx 5s linear infinite;
    @include breakpoint(max-xxl) {
      opacity: 0.2;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .feature__ai {
    position: absolute;
    top: 80px;
    right: 60px;
    @include breakpoint(max-lg) {
      top: 230px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .light__element1 {
    position: absolute;
    top: 195px;
    left: 164px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element2 {
    position: absolute;
    top: 290px;
    left: 40%;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element3 {
    position: absolute;
    top: 55%;
    left: 60%;
    transform: translate(-50%);
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element4 {
    position: absolute;
    bottom: 230px;
    right: 193px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
}
.feature__thumb {
  width: 100%;
  padding: 30px;
  animation: bns1 5s linear infinite;
  img {
    width: 100%;
  }
  @include breakpoint(max-xl) {
    display: none;
  }
}
.feature__right__align {
  .feature__items {
    text-align: right;
    .icon {
      margin: 0 0 0 auto;
    }
    @include breakpoint(max-lg) {
      text-align: left;
      .icon {
        margin: 0 auto 0 0;
      }
    }
  }
}
.feature__component__wrap {
  position: relative;
  z-index: 1;
  .feature__items {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    @include breakpoint(max-lg) {
      padding-left: 0;
    }
    .icon {
      margin-bottom: 16px;
      color: var(--themetext);
      width: 60px;
      height: 60px;
      line-height: 52px;
      text-align: center;
      border-radius: 5px;
      background: var(--base);
    }
    h4 {
      margin-bottom: 8px;
      color: var(--themetext);
    }
    p {
      font-size: 1rem;
      color: var(--themetext);
    }
  }
}
@media (min-width: 1200px) {
  .pl__space {
    padding-right: 40px;
  }
  .pr__space {
    padding-left: 40px;
  }
}
//Feature End//

//Feature Work Here//
.feature__work__section {
  position: relative;
  overflow: hidden;
  @include breakpoint(max-xl) {
    .row {
      justify-content: center !important;
    }
  }
  .work__element1 {
    position: absolute;
    bottom: 70px;
    left: 80px;
    animation: rlrl 5s linear infinite;
    width: 60px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      bottom: 20px;
      left: 20px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .work__lockyello {
    position: absolute;
    top: 100px;
    right: 100px;
    animation: rlrl 7s linear infinite;
    @include breakpoint(max-lg) {
      right: 30px;
      top: 30px;
    }
  }
  .work__blump {
    position: absolute;
    top: 120px;
    left: 130px;
    animation: rlrl 10s linear infinite;
    @include breakpoint(max-lg) {
      top: 30px;
      left: 30px;
      width: 35px;
      img {
        width: 100%;
      }
    }
  }
  .work__blump2 {
    position: absolute;
    bottom: 76px;
    right: 200px;
    animation: rlrl 10s linear infinite;
    width: 30px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xl) {
      bottom: 20px;
    }
  }
}
.feature__work__items {
  background: var(--tharbg1);
  display: flex;
  padding: 22px 24px 24px;
  border-radius: 5px;
  gap: 12px 20px;
  text-align: right;
  position: relative;
  border: 1px solid var(--tharbor1);
  @include breakpoint(max-xxl) {
    padding: 20px 10px 20px;
  }
  &::before {
    position: absolute;
    right: -120px;
    top: 60px;
    content: "";
    width: 145px;
    height: 60px;
    background: url(../../public/img/feature/v1.png) no-repeat center center;
    background-size: contain;
    @include breakpoint(max-xl) {
      display: none;
    }
  }
  &:nth-child(1) {
    margin-bottom: 150px;
    @include breakpoint(max-xl) {
      margin-bottom: 40px;
    }
  }
  .content {
    width: calc(100% - 60px);
    h5 {
      color: var(--themetext);
      margin-bottom: 4px;
    }
    p {
      font-size: 0.9rem;
      color: var(--themetext);
    }
  }
  .icon {
    text-align: center;
    line-height: 55px;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: var(--thardbox1);
  }
  &:nth-child(2) {
    background: var(--tharbg2);
    border: 1px solid var(--tharbor2);
    .icon {
      background: var(--thardbox2);
    }
  }
}
.feature__work__items2 {
  background: var(--tharbg3);
  display: flex;
  padding: 22px 24px 24px;
  border-radius: 5px;
  gap: 12px 20px;
  position: relative;
  border: 1px solid var(--tharbor3);
  @include breakpoint(max-xxl) {
    padding: 20px 10px 20px;
  }
  &::before {
    position: absolute;
    left: -120px;
    top: 60px;
    content: "";
    width: 145px;
    height: 60px;
    background: url(../../public/img/feature/v2.png) no-repeat center center;
    background-size: contain;
    @include breakpoint(max-xl) {
      display: none;
    }
  }
  &:nth-child(1) {
    margin-bottom: 150px;
    @include breakpoint(max-xl) {
      margin-bottom: 40px;
    }
  }
  &:nth-child(2) {
    background: var(--tharbg4);
    border: 1px solid var(--tharbor4);
    .icon {
      background: var(--thardbox4);
    }
  }
  .content {
    width: calc(100% - 60px);
    h5 {
      color: var(--themetext);
      margin-bottom: 4px;
    }
    p {
      font-size: 0.9rem;
      color: var(--themetext);
    }
  }
  .icon {
    text-align: center;
    line-height: 55px;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: var(--thardbox3);
  }
}
.feature__work__thumb {
  width: 100%;
  background: var(--fwork);
  border-radius: 7px;
  padding: 22px 60px 50px;
  animation: workdown 5s linear infinite;
  @include breakpoint(max-xl) {
    margin: 0 auto;
  }
  img {
    width: 100%;
  }
}
//Feature End//

//five work Here//
.five__work__section {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    content: "";
    top: -100px;
    left: -100px;
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.3;
    filter: blur(200px);
  }
  &::after {
    position: absolute;
    content: "";
    bottom: -100px;
    right: -100px;
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.3;
    filter: blur(200px);
  }
  .jav__round {
    position: absolute;
    top: 90px;
    right: 90px;
    width: 80px;
    animation: cir360 15s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-lg) {
      width: 40px;
      top: 20px;
      right: 20px;
    }
  }
  .jthred__ball {
    position: absolute;
    bottom: 70px;
    left: 150px;
    opacity: 0.3;
    animation: cir360 10s linear infinite;
    width: 45px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xl) {
      display: none;
    }
  }
}
.five__work__items {
  background: var(--fivewbg1);
  padding: 22px 24px 24px;
  border-radius: 5px;
  gap: 12px 20px;
  border: 1px solid var(--fivewborder1);
  text-align: center;
  @include breakpoint(max-xxl) {
    padding: 20px 10px 20px;
  }
  .content {
    h5 {
      color: var(--themetext);
      margin-bottom: 4px;
    }
    p {
      font-size: 0.9rem;
      color: var(--themetext);
    }
  }
  .icon {
    text-align: center;
    line-height: 55px;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: var(--fivewbgbox1);
    border: 1px solid var(--fiveicon1bor);
    margin: 0 auto 20px;
  }
}
.five__work__2 {
  background: var(--fivewbg2);
  border: 1px solid var(--fivewborder2);
  margin-top: 60px;
  position: relative;
  @include breakpoint(max-md) {
    margin-top: 2px;
  }
  @include breakpoint(max-xl) {
    margin-top: 5px;
  }
  .icon {
    background: var(--fivewbgbox2);
    border: 1px solid var(--fiveicon2bor);
  }
  &::before {
    position: absolute;
    content: "";
    bottom: -60px;
    left: -150px;
    width: 250px;
    height: 100px;
    background: url(../../public/img/elements/work-element1.png) no-repeat center center;
    background-size: contain;
    @include breakpoint(max-xl) {
      display: none;
    }
  }
  &::after {
    position: absolute;
    content: "";
    top: -80px;
    right: -20px;
    width: 150px;
    height: 100px;
    background: url(../../public/img/elements/work-element3.png) no-repeat center center;
    background-size: contain;
    @include breakpoint(max-md) {
      display: none;
    }
  }
}
.five__work__3 {
  background: var(--fivewbg3);
  border: 1px solid var(--fivewborder3);
  .icon {
    background: var(--fivewbgbox3);
    border: 1px solid var(--fiveicon3bor);
  }
}
.five__work__4 {
  margin-top: 60px;
  background: var(--fivewbg4);
  border: 1px solid var(--fivewborder4);
  position: relative;
  @include breakpoint(max-xl) {
    margin-top: 2px;
  }
  .icon {
    background: var(--fivewbgbox4);
    border: 1px solid var(--fiveicon4bor);
  }
  &::before {
    position: absolute;
    content: "";
    bottom: -60px;
    left: -150px;
    width: 250px;
    height: 100px;
    background: url(../../public/img/elements/work-element2.png) no-repeat center center;
    background-size: contain;
    @include breakpoint(max-xl) {
      display: none;
    }
  }
}
//five work End//

//Qualified Here//
.qualified__section {
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.3;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 0px;
    right: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .light__element1 {
    position: absolute;
    top: 105px;
    left: 104px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element2 {
    position: absolute;
    top: 100px;
    right: 100px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element3 {
    position: absolute;
    bottom: 66px;
    right: 20%;
    transform: translate(-50%);
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
}
.qualified__content {
  .section__header {
    margin-bottom: 32px;
    @include breakpoint(max-sm) {
      margin-bottom: 20px;
    }
  }
  .counting__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
    justify-content: space-between;
    .counter__items {
      &:not(:last-child) {
        border-right: 1px dashed var(--countright);
        padding-right: 37px;
        @include breakpoint(max-sm) {
          padding-right: 0;
          border-right: none;
        }
      }
      @include breakpoint(max-sm) {
        width: calc(100% / 2);
      }
      .counter__content {
        .cont {
          margin-bottom: 4px;
          .odometer {
            font-size: 2.5rem;
            line-height: 120%;
            font-weight: 600;
            color: var(--themetext);
            padding: 0;
          }
          .plus__icon {
            font-size: 2.5rem;
            font-weight: 600;
            color: var(--themetext);
            line-height: 120%;
          }
        }
        @include breakpoint(max-sm) {
          .cont {
            .odometer {
              font-size: 2rem;
            }
            .plus__icon {
              font-size: 2rem;
            }
          }
          p {
            font-size: 0.9rem;
          }
        }
      }
      &:nth-child(1) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--base);
            }
            .plus__icon {
              color: var(--base);
            }
          }
        }
      }
      &:nth-child(2) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--basetwo);
            }
            .plus__icon {
              color: var(--basetwo);
            }
          }
        }
      }
      &:nth-child(3) {
        .counter__content {
          .cont {
            .odometer {
              color: var(--basethree);
            }
            .plus__icon {
              color: var(--basethree);
            }
          }
        }
      }
      p {
        color: var(--countptext);
        font-size: 1rem;
        font-weight: 500;
        display: block;
      }
    }
  }
  @include breakpoint(max-lg) {
    margin-bottom: 35px;
  }
}
.qualified__thumb {
  width: initial;
  @include breakpoint(max-lg) {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
//Qualified End//

//terms & condition Here//
.condition__terms__tab {
  border: 1px solid var(--success);
  border-radius: 5px;
  padding: 30px;
  .nav {
    display: grid;
    border: none;
    .nav-item {
      border: none;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .nav-link {
        border: none;
        padding: 0 0;
        background: var(--success);
        width: 100%;
        padding: 10px 5px 12px;
        border-radius: 5px;
        color: var(--white);
        transition: all 0.3s;
        &:hover {
          background: var(--base);
        }
      }
    }
  }
}
.terms__condition__body {
  .same__condition__items {
    &:not(:last-child) {
      margin-bottom: 40px;
      @include breakpoint(max-md) {
        margin-bottom: 30px;
      }
    }
  }
  .text__box__none {
    h2 {
      color: var(--themetext);
      margin-bottom: 20px;
      @include breakpoint(max-md) {
        font-size: 27px;
      }
    }
    h3 {
      color: var(--themetext);
      @include breakpoint(max-md) {
        font-size: 32px;
      }
    }
    h4 {
      color: var(--themetext);
    }
    p {
      color: var(--themetext);
      font-size: 16px;
    }
    .list {
      margin-top: 25px;
      li {
        font-size: 16px;
        color: var(--themetext);
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
//terms & condition End//

//Capabilites Here//
.capabilities__section {
  position: relative;
  overflow: hidden;
  .capa__mask {
    position: absolute;
    top: 0;
    right: 0;
    @include breakpoint(max-lg) {
      opacity: 0.4;
    }
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 43%;
    width: 100%;
    content: "";
    background: var(--whitebgdark);
  }
  .global__mask {
    position: absolute;
    bottom: 100px;
    left: 100px;
    @include breakpoint(max-xxl) {
      bottom: 50px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
    .light__element1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .capa__ai {
    position: absolute;
    top: 80px;
    left: 80px;
    width: 80px;
    animation: smothx 5s linear infinite;
    img {
      width: 100%;
    }
  }
  .capa__rocket {
    position: absolute;
    bottom: 150px;
    right: 60px;
    width: 90px;
    animation: smothx 5s linear infinite;
    @include breakpoint(max-xxl) {
      bottom: 50px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .light__element1 {
    position: absolute;
    top: 120px;
    right: 140px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .redlight {
    position: absolute;
    top: 50px;
    right: 50px;
    animation: zminopa 3s linear infinite;
  }
  .case__rocket {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 200px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      top: 10%;
    }
    @include breakpoint(max-xl) {
      top: 10%;
      width: 100px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__redb {
    position: absolute;
    bottom: 40px;
    right: 40%;
  }
  .light__redt {
    position: absolute;
    top: 280px;
    left: 25%;
  }
  .spring__case {
    position: absolute;
    top: 60px;
    left: 60px;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-md) {
      display: none;
    }
  }
}
.capabilites__header {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint(max-lg) {
    flex-wrap: wrap;
    gap: 25px 0;
  }
  .section__header {
    max-width: 630px;
  }
}
.capabilities__wrap {
  .capabilities__items {
    margin-bottom: 35px;
    .thumb {
      &::before {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.3);
        content: "";
        opacity: 0;
        border-radius: 5px;
        transition: all 0.4s;
      }
    }
    &:hover {
      .thumb {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  .owl-dots,
  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 8px;

    li {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 8px;
      height: 8px;
      border-radius: 50% !important;

      button {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;

        background-color: var(--dots);
        width: 8px;
        height: 8px;
        margin: 0 !important;
        border-radius: 50% !important;

        &::before {
          content: "";
        }
      }
      &.slick-active {
        button {
          background-color: var(--base);
        }
      }
    }
  }
}
.capabilities__items {
  padding: 8px;
  background: var(--white);
  // @TODO: edit
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
  // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  .thumb {
    display: block;
    width: 100%;
    position: relative;
    img {
      width: 100%;
      border-radius: 5px;
    }
    .play__btn {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: var(--intellicon);
      text-align: center;
      line-height: 60px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 0%;
      opacity: 0;
      transition: all 0.4s;
      i {
        font-size: 26px;
        color: #2a1d95;
      }
    }
  }
  .content {
    padding: 24px 20px;
    .capabilites__btn {
      margin-bottom: 8px;
      border: 5px;
      color: var(--basetwo);
      background: rgba(0, 92, 48, 0.05);
    }
    h4 {
      @include breakpoint(max-xxl) {
        font-size: 1.2rem;
      }
      a {
        color: var(--countptext);
      }
      margin-bottom: 8px;
    }
    p {
      color: var(--themetext);
      font-size: 1rem;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .capa__more {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: var(--base);
        font-weight: 600;
        font-size: 1rem;
      }
      i {
        color: var(--achackcion);
        font-size: 0.9rem;
      }
    }
  }
  &:hover {
    .thumb {
      .play__btn {
        left: 50%;
        opacity: 1;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .capabilities__section {
    .capa__ai {
      opacity: 0.1;
    }
  }
  .efective__section {
    position: relative;
    .eye__elements {
      opacity: 0.1;
    }
  }
}
//Capabilites Two Here//
.case__trough {
  .capabilities__items {
    position: relative;
    padding: 0;
    z-index: 1;
    box-shadow: none;
    background: none;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .thumb {
      padding: 8px;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
    }
    .content {
      text-align: center;
      transform: translateY(-80px);
      margin: 0 30px -80px;
      background: var(--white);
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      @include breakpoint(max-xl) {
        h4 {
          a {
            font-size: 1rem;
          }
        }
      }
      @include breakpoint(max-lg) {
        h4 {
          a {
            font-size: 1.1rem;
          }
        }
      }
      @include breakpoint(max-md) {
        h4 {
          a {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .capa__case__boxmiddle {
    margin-top: -60px;
    @include breakpoint(max-lg) {
      margin-top: -0px;
    }
  }
}
.capa__flex__small {
  @include breakpoint(max-lg) {
    display: flex;
  }
  @include breakpoint(max-sm) {
    display: grid;
  }
}
.capa__flex__lg {
  @include breakpoint(max-lg) {
    display: flex;
    flex-wrap: wrap;
    .capabilities__items {
      width: calc(100% / 2);
      padding: 0px;
    }
  }
  @include breakpoint(max-sm) {
    display: flex;
    flex-wrap: wrap;
    .capabilities__items {
      width: calc(100%);
      padding: 10px;
    }
  }
}
.mt__customcase {
  margin-top: 28px;
  @include breakpoint(max-sm) {
    margin-top: -25px;
  }
}
.case__btn {
  text-align: center;
}
.small__space__capa {
  @include breakpoint(max-sm) {
    margin-bottom: 60px;
  }
}
//Case Different Here//
.case__different__section {
  position: relative;
  .diferent__element1 {
    position: absolute;
    bottom: 70px;
    right: 70px;
    animation: rlrl 5s linear infinite;
    @include breakpoint(max-lg) {
      width: 50px;
      bottom: 20px;
      right: 30px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-xs) {
      bottom: 10px;
      width: 30px;
    }
  }
  .diferent__lockyello {
    position: absolute;
    bottom: 70px;
    left: 120px;
    animation: rlrl 7s linear infinite;
    @include breakpoint(max-lg) {
      left: 60px;
      bottom: 40px;
    }
  }
  .capabilities__items {
    display: flex;
    align-items: center;
    @include breakpoint(max-md) {
      display: grid;
    }
    .thumb {
      max-width: 300px;
      height: 300px;
      background: var(--white);
      img {
        width: 100%;
        height: 100%;
      }
      @include breakpoint(max-xl) {
        max-width: 200px;
        max-height: 200px;
      }
      @include breakpoint(max-lg) {
        max-width: 300px;
        max-height: 300px;
      }
      @include breakpoint(max-md) {
        max-width: 100%;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
    .content {
      width: calc(100% - 300px);
      transform: translateY(-0px);
      text-align: left;
      margin: 0 -30px -0px;
      z-index: 1;
      .difference__btn {
        display: flex;
        align-items: center;
        gap: 4px;
        span {
          font-size: 1rem;
          font-weight: 600;
          color: var(--casedifrent);
        }
        .icon {
          margin-top: 8px;
          i {
            font-size: 18px;
            color: var(--achackcion);
          }
        }
      }
      @include breakpoint(max-xl) {
        width: calc(100% - 200px);
        h4 {
          margin-bottom: 8px;
        }
        p {
          font-size: 0.9rem;
          margin-bottom: 10px;
        }
      }
      @include breakpoint(max-md) {
        width: calc(100%);
        margin: 0 -0px 10px;
      }
    }
  }
  .diferent__blump {
    position: absolute;
    top: 80px;
    left: 100px;
    animation: rlrl 10s linear infinite;
    @include breakpoint(max-lg) {
      width: 30px;
      top: 30px;
      left: 30px;
      img {
        width: 100%;
      }
    }
  }
  .diferent__blump2 {
    position: absolute;
    right: 120px;
    top: 60px;
    width: 30px;
    animation: rlrl 12s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-lg) {
      width: 30px;
      top: 30px;
      right: 30px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-sm) {
      width: 30px;
      top: 10px;
      right: 10px;
      img {
        width: 100%;
      }
    }
  }
  .capabilities__items {
    @include breakpoint(max-md) {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}
//Capabilites End//

//Studies one Here//
.studies__wrap__one {
  .capabilities__items {
    .thumb {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        background: rgba(179, 170, 250, 0.3);
        border-radius: 5px;
        left: 0;
        width: 0;
        height: 0;
        transition: all 0.4s;
      }
    }
    &:hover {
      .thumb {
        &::before {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.education__studies__categories {
  padding: 0 20px 15px;
  @include breakpoint(max-sm) {
    padding: 0 10px 10px;
  }
}
.studies__details__items {
  .content {
    border-bottom: unset !important;
    margin-bottom: 14px !important;
    p {
      padding-bottom: 0;
    }
  }
}
.studies__details__items2 {
  border-bottom: unset !important;
  padding-bottom: 0 !important;
  .content {
    border-bottom: unset !important;
  }
}
.education__studies__wrap {
  border: 1px solid var(--place2bor);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .education__list {
    width: 100%;
    padding: 16px;
    li {
      color: var(--themetext);
      font-weight: 600;
      font-size: 1rem;
      line-height: 150%;
      padding: 8px 0;
      &:not(:last-child) {
        border-bottom: 1px dashed var(--place2bor);
      }
    }
  }
  .education__list__right {
    background: var(--brighterbg);
    li {
      font-weight: 400;
    }
    .ratting {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        i {
          font-size: 24px;
          color: var(--intellicon);
        }
      }
    }
  }
  @include breakpoint(max-xs) {
    flex-wrap: wrap;
  }
}
.machine__listing__edu {
  li {
    display: flex;
    gap: 5px;
    span {
      color: var(--prightercolor);
      font-size: 1rem;
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
.edu__client {
  padding: 24px 0;
  position: relative;
  .edu__items {
    background: var(--quitboxbg);
    padding: 32px 35px;
    border-radius: 5px;
    .edu__boxes {
      max-width: 600px;
      margin: 0 auto;
      .icon {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        transform: rotate(180deg);
        border: 1px solid var(--quiteborder);
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--quitibg);
        margin: 0 auto 16px;
        i {
          font-size: 56px;
          color: var(--quitcolor);
        }
      }
      .ratting {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: center;
        margin-bottom: 12px;
        span {
          i {
            font-size: 24px;
            color: var(--intellicon);
          }
        }
      }
      p {
        text-align: center;
        font-size: 16px;
        color: var(--themetext);
        margin-bottom: 28px;
      }
      .user__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .user__text {
          h5 {
            font-size: 20px;
            color: var(--themetext);
            margin-bottom: 4px;
          }
          .desig {
            font-size: 1rem;
            color: var(--themetext);
          }
        }
      }
    }

    @include breakpoint(max-xs) {
      padding: 32px 10px;
      .edu__boxes {
        p {
          font-size: 14px;
        }
      }
    }
  }
  .owl-nav {
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      background: var(--quitboxbg);
      border-radius: 5px;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
      position: absolute;
      top: 50%;
      left: 28px;
      transform: translateY(-50%);
      @include breakpoint(max-xxl) {
        left: -28px;
      }
      @include breakpoint(max-xs) {
        width: 26px;
        height: 26px;
        left: -20px;
        i {
          font-size: 18px;
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      background: var(--quitboxbg);
      border-radius: 5px;
      transition: all 0.3s;
      i {
        font-size: 26px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
      position: absolute;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      @include breakpoint(max-xxl) {
        right: -28px;
      }
      @include breakpoint(max-xs) {
        width: 26px;
        height: 26px;
        right: -20px;

        i {
          font-size: 18px;
        }
      }
    }
  }
}
//Studies one End//

//support rattting Here//
.ratting__support {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 100px;
    right: 0;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.3;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .support__element1 {
    position: absolute;
    bottom: 100px;
    left: 130px;
    animation: rlrl 7s linear infinite;
    @include breakpoint(max-xxl) {
      bottom: 30px;
      left: 30px;
      width: 50px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-md) {
      width: 25px;
      bottom: 10px;
    }
  }
  .support__lockyello {
    position: absolute;
    bottom: 100px;
    right: 80px;
    animation: rlrl 7s linear infinite;
    @include breakpoint(max-xxl) {
      right: 30px;
      bottom: 30px;
    }
    @include breakpoint(max-md) {
      bottom: 10px;
    }
  }
  .support__blump {
    position: absolute;
    top: 100px;
    right: 60px;
    animation: rlrl 7s linear infinite;
    @include breakpoint(max-xxl) {
      top: 30px;
      right: 30px;
    }
    @include breakpoint(max-md) {
      width: 30px;
      top: 10px;
      right: 10px;
      img {
        width: 100%;
      }
    }
  }
  .support__gold {
    position: absolute;
    top: 60px;
    left: 100px;
    @include breakpoint(max-xxl) {
      top: 30px;
      left: 30px;
      width: 70px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-lg) {
      width: 40px;
      top: 10px;
      left: 10px;
    }
    @include breakpoint(max-xs) {
      display: none;
    }
  }
}
.support__header {
  @include breakpoint(max-lg) {
    margin-bottom: 30px;
  }
}
.ratt__supp__items {
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  background: var(--suppitems);
  @include breakpoint(max-xl) {
    padding: 10px;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  &:nth-child(2) {
    @include breakpoint(xl) {
      transform: translateX(50px);
    }
  }
  .left__thumb {
    .thumb {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .ratting__wrap {
      background: #6552f6;
      padding: 0 30px 22px;
      .top__ratting {
        transform: translateY(-40px);
        margin: 0 auto -40px;
        text-align: center;
      }
      .ratting {
        padding-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        i {
          color: var(--white);
          font-size: 22px;
        }
      }
    }
  }
  .right__content {
    padding: 14px 24px;
    @include breakpoint(max-xl) {
      padding: 14px 14px;
    }
    .head__designation {
      display: flex;
      align-items: center;
      gap: 12px 24px;
      margin-bottom: 16px;
      @include breakpoint(max-xl) {
        gap: 10px 14px;
      }
      .icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;
        border: 1px solid var(--subborder);
        i {
          font-size: 37px;
          color: #b5acfb;
        }
      }
      .name {
        h5 {
          margin-bottom: 4px;
          color: var(--themetext);
          @include breakpoint(max-xl) {
            font-size: 1.1rem;
          }
        }
        .desig {
          color: var(--themetext);
          font-size: 1rem;
        }
      }
    }
    p {
      color: var(--themetext);
      font-size: 1rem;
    }
  }
  @include breakpoint(max-xs) {
    flex-wrap: wrap;
    justify-content: center;
    .right__content {
      .head__designation {
        justify-content: center;
      }
      p {
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }
}
//support rattting End//

//plan Here//
.plan__section {
  position: relative;
  overflow: hidden;
  .light__element1 {
    position: absolute;
    top: 70px;
    left: 205px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element2 {
    position: absolute;
    top: 70px;
    right: 55px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__threed {
    position: absolute;
    top: 30%;
    left: 20%;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-xxl) {
      top: 20px;
      left: 20px;
    }
    @include breakpoint(max-xs) {
      display: none;
    }
  }
  .plan__man {
    position: absolute;
    right: 40px;
    bottom: 50px;
    animation: smothx 5s linear infinite;
    @include breakpoint(max-xxl) {
      right: 10px;
      bottom: 10px;
      width: 120px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .plan__ai {
    position: absolute;
    top: 120px;
    right: 120px;
    @include breakpoint(max-xxl) {
      top: 10px;
      right: 10px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .plan__ball {
    position: absolute;
    bottom: 120px;
    left: 60px;
    @include breakpoint(max-xxl) {
      left: 10px;
      bottom: 10px;
    }
    @include breakpoint(max-xs) {
      width: 35px;
      img {
        width: 100%;
      }
    }
  }
  .owl-nav {
    position: absolute;
    left: -109%;
    bottom: 80px;
    @include breakpoint(max-xxl) {
      bottom: 50px;
    }
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      // @TODO: add background-color: ;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      // @TODO: add background-color: ;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
  }
}
.plan__content__left {
  @include breakpoint(max-xl) {
    margin-top: 15px;
  }
}
.plan__items {
  background: var(--white);
  border-radius: 10px 10px 10px 10px;
  .plan__head {
    background: var(--box1);
    border-radius: 10px 10px 0 0;
    text-align: center;
    padding: 24px 10px;
    h4 {
      color: var(--themetext);
    }
    p {
      color: var(--themetext);
      font-size: 1rem;
    }
  }
  .content__wrap {
    padding: 24px 32px;
    .prices__area {
      text-align: center;
      border-bottom: 1px solid var(--priceborder);
      .icon {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        padding: 10px;
        margin: 0 auto 16px;
        background: var(--box1);
        border: 1px solid var(--boxborder);
        i {
          font-size: 36px;
          color: var(--base);
        }
      }
      h3 {
        color: var(--themetext);
        .dollar {
          font-size: 16px;
          font-weight: 600;
          color: var(--themetext);
        }
      }
    }
    .plan__list {
      display: grid;
      justify-content: center;
      padding: 16px 0;
      li {
        display: flex;
        align-items: center;
        gap: 4px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        span {
          font-size: 0.9rem;
          color: var(--themetext);
        }
        .icon {
          i {
            font-size: 0.9rem;
            line-height: 30px;
            color: var(--base);
          }
        }
      }
    }
    .price__btn {
      text-align: center;
      .border__btn {
        color: var(--base);
        display: block;
      }
    }
  }
}
.premium__items {
  .plan__head {
    background: var(--box2);
  }
  .content__wrap {
    .prices__area {
      .icon {
        background: var(--box2);
        border: 1px solid var(--planbuttonborder);
        i {
          color: #005c30;
        }
      }
    }
    .plan__list {
      li {
        .icon {
          i {
            color: var(--basetwo);
          }
        }
      }
    }
    .price__btn {
      .border__btn {
        color: var(--basetwo);
        border: 1px solid var(--planbuttonborder);
        padding: 10px 8px 14px;
      }
    }
  }
}
//plan Two Start//
.plan__section__two {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: -100px;
    left: -100px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.2;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 100px;
    right: -100px;
    content: "";
    width: 300px;
    height: 200px;
    background: #a21111;
    opacity: 0.2;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
}
.plan__save {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px 16px;
  .month {
    font-size: 1.3rem;
    color: var(--themetext);
    font-weight: 500;
    line-height: 130%;
  }
  h5 {
    font-weight: 500;
    color: var(--themetext);
    margin-bottom: 0;
    span {
      color: var(--save30);
    }
  }
  .yearly__bar {
    .form-check-input {
      background-color: var(--cswitch);
      width: 70px;
      height: 35px;
      border: none;
      outline: none;
      box-shadow: none;
      background-image: url(../../public/img/elements/click.png);
      background-size: 32px;
    }
  }
  @include breakpoint(max-xs) {
    flex-wrap: wrap;
    h5 {
      font-size: 1rem;
    }
    .month {
      font-size: 1rem;
    }
  }
}
.plan__save__four {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px 24px;
  .pricing__chaek__items {
    display: flex;
    align-items: center;
    gap: 8px;
    .form-check-input {
      box-shadow: none;
      outline: none;
    }
    .form-check-label {
      color: var(--themetext);
      font-weight: 500;
    }
  }
  .pricing__sav {
    color: #a21111;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -40px;
    margin-left: 30px;
    position: relative;
    .icon {
      position: absolute;
      top: calc(100% - 5px);
      left: -40px;
      width: 40px;
      img {
        width: 100%;
      }
    }
    .icon2 {
      display: none;
    }
  }

  @include breakpoint(max-xs) {
    flex-wrap: wrap;
    .pricing__sav {
      margin-top: 0px;
    }
  }
}
.plan__items__two {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--pribox1);
  padding: 20px 32px 24px;
  @include breakpoint(max-xxl) {
    flex-wrap: wrap;
    .plan__valu__left {
      margin-right: 0;
      padding-right: 0;
      border-right: none !important;
      margin-bottom: 20px;
    }
  }
  @include breakpoint(max-xs) {
    flex-wrap: wrap;
    .plan__valu__left {
      border-right: unset !important;
    }
  }
  .plan__valu__left {
    border-right: 1px solid #ebedf0;
    padding-right: 24px;
    margin-right: 24px;
  }
  .plan__head {
    background: unset;
    padding: 0;
  }
  .prices__area {
    text-align: left;
    border-bottom: unset;
    h4 {
      color: var(--base);
    }
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      padding: 10px;
      margin: 0 0 16px 0;
      background: var(--box1);
      border: 1px solid var(--boxborder);
      i {
        font-size: 36px;
        color: var(--base);
      }
    }
    h3 {
      color: var(--themetext);
      margin-bottom: 4px;
      .dollar {
        font-size: 16px;
        font-weight: 600;
        color: var(--themetext);
      }
    }
    h6 {
      color: var(--themetext);
    }
  }
  .content__wrap {
    padding: 0;
    .plan__list {
      padding: 0;
      justify-content: flex-start;
    }
    .price__btn {
      margin-top: 22px;
    }
  }
}
.plan__items__two1 {
  background: var(--pribox2);
  .prices__area {
    h4 {
      color: var(--basetwo);
    }
    .icon {
      background: var(--box2);
      border: 1px solid var(--planbuttonborder);
      i {
        color: var(--basetwo);
      }
    }
  }
}
.plan__items__two2 {
  background: var(--pribox3);
  .prices__area {
    h4 {
      color: var(--themeneed);
    }
    .icon {
      background: var(--priboxicon);
      border: 1px solid var(--coubor1);
      i {
        color: var(--themeneed);
      }
    }
  }
}
.plan__items__two3 {
  background: var(--businessbg);
  .prices__area {
    h4 {
      color: var(--basethree);
    }
    .icon {
      background: var(--busnesicon);
      border: 1px solid var(--busnesborder);
      i {
        color: var(--basethree);
      }
    }
  }
}
//plan Three Start//
.plan__section__three {
  position: relative;
  .plan__ballr {
    right: 150px;
    position: absolute;
    bottom: 100px;
    @include breakpoint(max-lg) {
      bottom: 30px;
      right: 30px;
      width: 60px;
      img {
        width: 100%;
      }
    }
  }
  .plan__aithree {
    position: absolute;
    bottom: 70px;
    left: 70px;
    @include breakpoint(max-xxl) {
      bottom: 30px;
    }
    @include breakpoint(max-lg) {
      left: 10px;
      bottom: 10px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .light__real {
    position: absolute;
    top: 70px;
    right: 70px;
    @include breakpoint(max-xl) {
      top: 30px;
      width: 60px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-lg) {
      top: 30px;
      right: 30px;
    }
  }
  .digital__ball {
    position: absolute;
    top: 60px;
    right: 60px;
    animation: cir360 15s linear infinite;
    width: 60px;
    img {
      width: 100%;
    }
    @include breakpoint(max-sm) {
      top: 10px;
      right: 10px;
      width: 35px;
    }
  }
  .digital__ballelement {
    position: absolute;
    bottom: 60px;
    left: 60px;
    animation: cir360 15s linear infinite;
    width: 50px;
    opacity: 0.5;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      bottom: 10px;
      left: 10px;
      width: 35px;
    }
  }
  .digital__round {
    position: absolute;
    top: 120px;
    left: 300px;
    width: 40px;
    animation: cir360 15s linear infinite;
    opacity: 0.3;
    img {
      width: 100%;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .plan__items {
    // @TODO: edit
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
    // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    // margin: 10px;
    margin-bottom: 15px;

    @include breakpoint(max-xs) {
      margin: 5px 5px 30px 5px;
    }
    .plan__head {
      background: var(--white);
    }
    .content__wrap {
      padding: 4px 32px 24px;
      .prices__area {
        background: var(--pribox1);
        border-radius: 5px;
        padding: 14px 16px 16px;
        h3 {
          color: #3021ab;
          margin-bottom: 0;
          .dollar {
            color: #3021ab;
          }
        }
        .pricetext {
          color: var(--themetext);
        }
      }
    }
  }

  .owl-nav {
    position: absolute;
    left: 770px;
    bottom: 30px;
    display: flex;
    align-items: center;
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      transition: all 0.3s;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    @include breakpoint(max-xxl) {
      left: 660px;
      bottom: 20px;
    }
    @include breakpoint(max-xl) {
      display: none !important;
    }
  }
}
//plan four Here//
.plan__section__four {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: -10px;
    left: -100px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.2;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 100px;
    right: -10px;
    content: "";
    width: 300px;
    height: 300px;
    background: #edcb50;
    opacity: 0.2;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .plan__items__two2,
  .plan__items__two1,
  .plan__items__two {
    .plan__valu__left {
      border-right: unset;
      border-bottom: 1px solid var(--priicecustomborder);
      .prices__area {
        display: flex;
        gap: 10px 20px;
        align-items: center;
        .content__small {
          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
    .content__wrap {
      padding-top: 16px;
      justify-content: flex-start;
    }
  }
}
//plan Five Here//
.table__main {
  @include breakpoint(max-lg) {
    overflow-x: auto;
  }
}
.pricing__table {
  width: 100%;
  @include breakpoint(max-lg) {
    width: 800px;
  }
  tr {
    &:nth-child(odd) {
      background: var(--oddbg);
    }
    th {
      padding: 12px 12px;
      text-align: center;
      .title {
        color: #354764;
        margin-bottom: 4px;
      }
      .price__area {
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;
        .dollar {
          font-size: 0.9rem;
          color: var(--pinfotext1);
        }
        .price {
          font-size: 1.3rem;
          color: var(--pinfotext1);
        }
        .month {
          font-size: 0.9rem;
          font-weight: 500;
          color: var(--themetext);
        }
      }
      &:nth-child(2) {
        .price__area {
          .dollar {
            color: var(--pinfotext2);
          }
          .price {
            color: var(--pinfotext2);
          }
        }
      }
      &:nth-child(3) {
        .price__area {
          .dollar {
            color: var(--pinfotext3);
          }
          .price {
            color: var(--pinfotext3);
          }
        }
      }
      &:nth-child(4) {
        .price__area {
          .dollar {
            color: var(--pinfotext4);
          }
          .price {
            color: var(--pinfotext4);
          }
        }
      }
    }
    td {
      color: var(--themetext);
      padding: 7px 7px;
      font-size: 1rem;
      i {
        font-size: 1rem;
      }
      &:nth-child(2) {
        .cmn--btn {
          background: unset;
          color: var(--base);
          border: 1px solid var(--tharbor1);
        }
      }
      &:nth-child(3) {
        .cmn--btn {
          background: unset;
          color: var(--basetwo);
          border: 1px solid var(--tharbor3);
        }
      }
      &:nth-child(4) {
        .cmn--btn {
          background: unset;
          color: var(--themeneed);
          border: 1px solid var(--tharbor4);
        }
      }
      &:nth-child(5) {
        .cmn--btn {
          background: unset;
          color: var(--basethree);
          border: 1px solid var(--inbox4);
        }
      }
    }
    &:last-child {
      background: unset;
    }
  }
}
//plan End//

//Success Story Here//
.success__story {
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 80%;
    height: 100%;
    background: var(--bgsection);
  }
  .d__light {
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 0.4;
    @include breakpoint(max-md) {
      top: 10px;
      right: 10px;
    }
  }
  .d__ball {
    position: absolute;
    bottom: 80px;
    left: 80px;
    width: 40px;
    animation: cir360 15s linear infinite;
    opacity: 0.4;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      left: 20px;
      bottom: 20px;
    }
  }
  .d__round {
    position: absolute;
    bottom: 40px;
    right: 16%;
    width: 40px;
    animation: cir360 15s linear infinite;
    opacity: 0.4;
    img {
      width: 100%;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .owl-nav {
    position: absolute;
    left: -47%;
    bottom: -20px;
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    @media screen and (max-width: 1700px) {
      display: none !important;
    }
  }
}
.success__story__wrap {
  @include breakpoint(max-xxl) {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1800px) {
  .success__content {
    margin-left: -0px;
    .section__header {
      h2 {
        font-size: 2rem;
      }
    }
  }
}
.success__content {
  position: relative;
  margin-left: -80px;
  @include breakpoint(max-xxl) {
    .section__header {
      text-align: center;
      max-width: 600px;
      margin: 0 auto 40px;
    }
  }
  @include breakpoint(max-lg) {
    margin-left: -144px;
  }
  @include breakpoint(max-md) {
    margin-left: -100px;
    .section__header {
      max-width: 450px;
    }
  }
  @include breakpoint(max-sm) {
    margin-left: -80px;
    .section__header {
      padding: 0px 40px 0 10px;
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
  @include breakpoint(max-xs) {
    margin-left: -30px;
  }
}
.success__stry__item {
  position: relative;
  .thumb {
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
      border-radius: 5px;
      transition: all 0.4s;
    }
  }
  .content {
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(0, 0, 0, 0.64) 43.23%,
      #000000 100%
    );
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 24px 24px;
    h4 {
      color: var(--white);
      margin-bottom: 8px;
    }
    p {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    a {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        color: #41e295;
        font-weight: 600;
      }
      .icon {
        margin-top: 7px;
        i {
          color: #41e295;
        }
      }
    }
  }
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
  }
}
//Success Story End//

//efective Here//
.efective__section {
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 200px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .eye__elements {
    position: absolute;
    top: 64px;
    right: 64px;
  }
  .efect__element1 {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 76px;
    left: 114px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .efect__element2 {
    position: absolute;
    bottom: 76px;
    right: 60px;
    animation: cirsle 2s linear infinite;
  }
  .efect__element3 {
    position: absolute;
    top: 0px;
    left: -100px;
    animation: cirsle 2s linear infinite;
  }
  .efect__element4 {
    position: absolute;
    top: 50%;
    left: -100px;
    animation: cir360 2s linear infinite;
  }
  .efect__element5 {
    position: absolute;
    top: 0;
    left: 0;
    animation: cirsmoth 2s linear infinite;
  }
  .efect__element6 {
    position: absolute;
    top: 0;
    right: 0;
    animation: cirsmoth 2s linear infinite;
  }
  .efect__element7 {
    position: absolute;
    top: 130px;
    right: 0;
    animation: cir360 2s linear infinite;
  }
}
.efective__thumb {
  position: relative;
  width: 100%;
  @include breakpoint(max-lg) {
    display: none;
  }
  img {
    width: 100%;
    animation: bns1 5s linear infinite;
  }
}
.efective__content {
  position: relative;
  z-index: 1;
  @include breakpoint(max-lg) {
    margin-bottom: 35px;
  }
  .efective__data__wrapper {
    .efect__data__iems {
      display: flex;
      align-items: center;
      gap: 10px 20px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .icons {
        width: 48px;
        height: 48px;
        text-align: center;
        line-height: 42px;
        border-radius: 5px;
        background: rgba(69, 47, 244, 0.05);
        border: 1px solid var(--box1);
      }
      .icons2 {
        background: rgba(40, 138, 91, 0.05);
        border: 1px solid #e6efea;
      }
      .icons3 {
        background: rgba(107, 74, 0, 0.05);
        border: 1px solid #f0ede6;
      }
      .icons4 {
        background: rgba(162, 17, 17, 0.05);
        border: 1px solid #f6e7e7;
      }
      .content {
        width: calc(100% - 48px);
        h5 {
          color: var(--themetext);
          margin-bottom: 4px;
        }
        p {
          font-size: 0.9rem;
          color: var(--themetext);
        }
      }
    }
  }
}
//efective Here//
.efective__system__section {
  position: relative;
  overflow: hidden;
  .efect__three {
    position: absolute;
    top: 100px;
    left: 100px;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .efect__rount {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%);
    animation: cir360 10s linear infinite;
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__cross {
    position: absolute;
    top: 40%;
    left: 42%;
    animation: cir360 10s linear infinite;
    @include breakpoint(max-xxl) {
      display: none;
    }
  }
  &::before {
    display: none;
  }
}
.efective__system {
  width: initial;
  @include breakpoint(max-lg) {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.efective__data__system {
  .efect__data__iems {
    display: flex;
  }
}
//efective Three Here//
.efective__section__three {
  overflow: hidden;
  position: relative;
  &::before {
    position: absolute;
    bottom: -50px;
    left: -50px;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.4;
    filter: blur(250px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: -60px;
    right: -60px;
    content: "";
    width: 300px;
    height: 300px;
    background: #452ff4;
    opacity: 0.4;
    filter: blur(250px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .efect__element4 {
    top: 35px;
  }
  .efect__light1 {
    position: absolute;
    top: 60px;
    right: 60px;
  }
  .efect__light2 {
    position: absolute;
    bottom: 120px;
    left: 55%;
  }
  .efect__tb1 {
    position: absolute;
    top: 150px;
    left: 90px;
    @include breakpoint(max-xxl) {
      top: 20px;
      left: 20px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__tbgold {
    position: absolute;
    bottom: 40px;
    left: 60px;
    width: 80px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xxl) {
      width: 60px;
      left: 10px;
      bottom: 10px;
    }
    @include breakpoint(max-sm) {
      width: 40px;
    }
  }
  .check__ai {
    position: absolute;
    bottom: 60px;
    right: 60px;
    width: 100px;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      width: 60px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efective__thumb {
    .efect__ecross {
      position: absolute;
      right: 10px;
      bottom: -60px;
      animation: cir360 7s linear infinite;
    }
    .efect__eleround {
      position: absolute;
      top: -40px;
      left: 0;
      @include breakpoint(max-lg) {
        top: 40px;
      }
    }
    .efect__elyellow {
      position: absolute;
      right: -50px;
      bottom: 30%;
    }
    .teser__trvuj {
      position: absolute;
      right: 0;
      top: 100px;
    }
    .efect__tablet {
      width: 30px;
      img {
        width: 100%;
      }
      position: absolute;
      right: -100px;
      top: 50%;
    }
  }
  .efective__content {
    .efective__data__wrapper {
      border-left: 1px dashed #c2c7d0;
      height: 100%;
      padding-left: 60px;
      position: relative;
      @include breakpoint(max-xs) {
        padding-left: 40px;
      }
      .content {
        @include breakpoint(max-xs) {
          h5 {
            font-size: 1rem;
          }
        }
      }
      &::before {
        position: absolute;
        left: -5px;
        top: -6px;
        width: 10px;
        height: 9px;
        content: "";
        background: #c2c7d0;
        clip-path: polygon(100% 100%, 49% 38%, 0 100%);
      }
      &::after {
        clip-path: polygon(100% 0, 50% 45%, 0 0);
        position: absolute;
        left: -7px;
        bottom: -8px;
        width: 12px;
        height: 12px;
        content: "";
        background: #c2c7d0;
      }
      .efect__data__iems {
        .icons {
          font-size: 1.5rem;
          font-weight: 600;
          color: var(--themetext);
          position: relative;
          &::before {
            position: absolute;
            right: calc(100% - -15px);
            top: 50%;
            width: 45px;
            height: 1px;
            content: "";
            border: 1px dashed #c2c7d0;
            @include breakpoint(max-xs) {
              width: 25px;
            }
          }
          &::after {
            position: absolute;
            right: calc(100% - -10px);
            top: 20px;
            width: 10px;
            height: 9px;
            content: "";
            background: #c2c7d0;
            clip-path: polygon(79% 48%, 0 0, 0 100%);
          }
        }
      }
    }
  }
}
//efective End//

//faq section Here//
.faq__section {
  overflow: hidden;
}
.accordion__thumb {
  width: 100%;
  position: relative;
  img {
    width: 100%;
  }
  .qustion {
    position: absolute;
    top: -10px;
    right: -140px;
    animation: somthted 10s linear infinite;
    @include breakpoint(max-xxl) {
      width: 150px;
      top: 5px;
      right: -80px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-lg) {
      top: 130px;
      right: -30px;
      width: 100px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-xs) {
      top: 30px;
      right: -5px;
      width: 60px;
      img {
        width: 100%;
      }
    }
  }
}
.accordion__wrap {
  .accordion-item {
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: var(--white);
    border: none;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .accordion-header {
      .accordion-button {
        border-radius: 5px;
        box-shadow: none;
        font-size: 1.1rem;
        line-height: 150%;
        font-weight: 500;
        color: var(--themetext);
        @include breakpoint(max-xs) {
          font-size: 1rem;
        }
        &::after {
          background: url(../../public/img/faq/plus.png) no-repeat center center;
          background-size: 2vh;
          background-color: var(--base);
          width: 25px;
          height: 25px;
          border-radius: 3.75px;
        }
      }
      .accordion-button:not(.collapsed)::after {
        background-image: none;
        transform: none;
        background: url(../../public/img/faq/remove.png) no-repeat center center;
        background-size: 2vh;
        background-color: var(--danger);
        width: 25px;
        height: 25px;
        border-radius: 3.75px;
      }
      .accordion-button:not(.collapsed) {
        color: var(--themetext);
        background-color: var(--white);
        box-shadow: none;
        border-bottom: 1px solid var(--priceborder);
      }
    }
  }
  .accordion-body {
    p {
      font-size: 0.9rem;
      color: var(--themetext);
      line-height: 150%;
    }
  }
}
//faq section Four Here//
.faq__section__four {
  position: relative;
  overflow: hidden;
  //faq section Four Here//
  .accordion__thumb__four {
    @include breakpoint(max-xxl) {
      width: 600px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-xl) {
      width: 500px;
      img {
        width: 100%;
      }
      @include breakpoint(max-lg) {
        display: none;
      }
    }
  }
}
.accordion__four__contnt {
  .accordion__wrap {
    .accordion-item {
      background: var(--accorlightbg);
      box-shadow: unset;
      .accordion-header {
        .accordion-button {
          background: var(--accorlightbg);
        }
        .accordion-button:not(.collapsed) {
          border-bottom: 1px solid var(--accorlight);
        }
      }
    }
  }
}
.accordion__thumb__four {
  position: absolute;
  bottom: 0;
  left: 0;
}
//main faq section Here//
.main__accordion__content {
  background: rgba(69, 47, 244, 0.05);
  border-radius: 5px;
  padding: 20px;
}
//faq section End//

//signin section Here//

.signin_pt {
  padding: 120px 0 0;
}
.sigin__page {
  position: relative;
  overflow: hidden;
  // TODO: Edit
  // padding: 120px 0 0;
  &::before {
    position: absolute;
    content: "";
    bottom: -80px;
    left: -80px;
    width: 300px;
    height: 300px;
    background: #ed5050;
    opacity: 0.6;
    filter: blur(200px);
  }
  &::after {
    position: absolute;
    content: "";
    bottom: -80px;
    right: -80px;
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.6;
    filter: blur(200px);
  }
}
.signin__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: -200px;
    left: -200px;
    width: 200px;
    height: 200px;
    background: #452ff4;
    opacity: 0.6;
    filter: blur(200px);
  }
  &::after {
    position: absolute;
    content: "";
    top: -200px;
    right: -200px;
    width: 200px;
    height: 200px;
    background: #ffaf00;
    opacity: 0.6;
    filter: blur(200px);
  }
  @include breakpoint(max-lg) {
    height: 100%;
    padding: 40px 0 80px;
  }
}
.signin__thumb {
  width: 100%;
  img {
    width: 100%;
  }
}
.signin__content__left {
  .signin__head {
    margin-bottom: 40px;
    h3 {
      color: var(--themetext);
      margin-bottom: 16px;
    }
    p {
      color: var(--themetext);
      font-size: 16px;
    }
  }
  form {
    .forget__right {
      text-align: right;
      margin-top: 12px;
      .forget {
        color: var(--casedifrent);
      }
    }
    .form__grp {
      label {
        font-size: 18px;
        font-weight: 500;
        color: var(--themetext);
        margin-bottom: 15px;
      }
      input {
        outline: none;
        color: var(--placeinput);
        width: 100%;
        background: rgba(69, 47, 244, 0.05);
        border: 1px solid var(--inputborder);
        border-radius: 5px;
        padding: 12px 20px 14px;
        font-size: 14px;
      }
      ::placeholder {
        color: var(--placeinput);
      }
    }
  }
  .accout {
    margin-top: 24px;
    font-size: 16px;
    color: var(--themetext);
    font-weight: 400;
    margin-bottom: 32px;
    a {
      color: var(--casedifrent);
      font-weight: 500;
    }
  }
}

.error__wrapper {
  .thumb {
    width: 100%;
    margin-bottom: 40px;
    img {
      width: 100%;
    }
  }
}
.error__contnet {
  text-align: center;
  margin: 0 auto;
  h2 {
    color: var(--themetext);
    margin-bottom: 20px;
    @include breakpoint(max-sm) {
      font-size: 32px;
    }
  }
  p {
    font-size: 16px;
    color: var(--themetext);
    margin-bottom: 32px;
  }
}
//signin section End//

//artificial Here//
.artificial__section {
  position: relative;
  overflow: hidden;
  &::before {
    background: #452ff4;
    filter: blur(300px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 300px;
    content: "";
  }
  &::after {
    background: #41e295;
    opacity: 0.4;
    filter: blur(300px);
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 200px;
    content: "";
  }
  .light__element1 {
    position: absolute;
    top: 129px;
    left: 174px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element2 {
    position: absolute;
    bottom: 110px;
    left: 309px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element3 {
    position: absolute;
    top: 150px;
    left: 42%;
    transform: translate(-50%);
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element4 {
    position: absolute;
    top: 109px;
    right: 153px;
    animation: zminopa 3s linear infinite;
    @include breakpoint(max-xxl) {
      top: 80px;
    }
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__element5 {
    position: absolute;
    bottom: 100px;
    right: 20%;
    animation: zminopa 2s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .light__checkai {
    position: absolute;
    bottom: 80px;
    right: 40px;
    width: 60px;
    @include breakpoint(max-md) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
}
.artificial__thumb {
  position: relative;
  direction: rtl;
  z-index: 1;
  @include breakpoint(max-xl) {
    max-width: 600px;
    img {
      width: 100%;
    }
  }
  @include breakpoint(max-lg) {
    margin-top: 30px;
  }
  @include breakpoint(max-md) {
    &::before {
      display: none;
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    content: "";
    width: 500px;
    z-index: -1;
    border-radius: 10px;
    height: 500px;
    border: 1px solid rgba(69, 47, 244, 0.3);
  }
  img {
    max-width: unset;
    animation: airtifi 5s linear infinite;
  }
}
.artificial__content {
  position: relative;
  &::before {
    background: #edcb50;
    opacity: 0.4;
    filter: blur(300px);
    position: absolute;
    bottom: -300px;
    right: -300px;
    width: 300px;
    height: 300px;
    content: "";
  }
  p {
    color: var(--themetext);
  }
  .artificial__app {
    padding: 32px 0;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(max-xl) {
      padding: 22px 0;
    }
    @include breakpoint(max-xs) {
      flex-wrap: wrap;
      gap: 15px;
    }
    .articial__items {
      position: relative;
      padding: 10px 30px;
      &::before {
        position: absolute;
        content: "";
        background: url(../../public/img/efective/fleft.png) no-repeat center center;
        background-size: contain;
        left: 0;
        width: 30px;
        height: 100%;
        top: 0;
      }
      &::after {
        position: absolute;
        content: "";
        background: url(../../public/img/efective/fright.png) no-repeat center center;
        background-size: contain;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
      }
      text-align: center;
      .apptext {
        font-size: 14px;
        line-height: 150%;
        display: block;
        color: var(--themetext);
      }
      .apppweek {
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
        display: block;
        color: var(--themetext);
      }
      .date {
        font-size: 14px;
        line-height: 150%;
        display: block;
        color: var(--themetext);
      }
    }
  }
}
//artificial End//

//Testimonial Here//
.testimonial__section {
  position: relative;
  overflow: hidden;
  .efect__element1 {
    position: absolute;
    bottom: 74px;
    left: 114px;
    animation: cir360 2s linear infinite;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .efect__element2 {
    position: absolute;
    left: 130px;
    top: 150px;
    animation: cir360 2s linear infinite;
    @include breakpoint(max-xxl) {
      top: 80px;
      left: 70px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__element4 {
    position: absolute;
    right: 50px;
    bottom: 50px;
    animation: cir360 2s linear infinite;
  }
  .efect__element7 {
    position: absolute;
    top: 50px;
    left: 50%;
    animation: cir360 2s linear infinite;
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .testimonial__wrap__wided,
  .testimonial__wrap {
    position: relative;
    .owl-nav {
      display: flex;
      margin-top: 20px;
      button.owl-prev {
        line-height: 6px;
        border: 1px solid var(--base);
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: transparent;
        transition: all 0.3s;
        i {
          font-size: 28px;
          color: var(--themetext);
          transition: all 0.3s;
        }
        &:hover {
          background: var(--base);
          i {
            color: var(--white);
          }
        }
      }
      button.owl-next {
        line-height: 6px;
        border: 1px solid var(--base);
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: transparent;
        transition: all 0.3s;
        i {
          font-size: 28px;
          line-height: 20px;
          color: var(--themetext);
        }
        &:hover {
          background: var(--base);
          i {
            color: var(--white);
          }
        }
      }
      @include breakpoint(max-xxl) {
        bottom: 15px;
        left: 50%;
        transform: translate(-50%);
      }
      @include breakpoint(max-sm) {
        display: none;
      }
    }
  }
}
.testimonial__content {
  position: relative;
  .section__header {
    h2 {
      margin-bottom: 0;
    }
  }
  .testimonial__fixed {
    position: absolute;
    top: 60%;
    right: 0;
    opacity: 0.5;
    @include breakpoint(max-lg) {
      top: 50%;
    }
    @include breakpoint(max-xs) {
      display: none;
    }
  }
  @include breakpoint(max-lg) {
    margin-bottom: 80px;
  }
  @include breakpoint(max-sm) {
    margin-bottom: 120px;
  }
  @include breakpoint(max-xs) {
    margin-bottom: 30px;
  }
}
.maxwidht {
  max-width: 420px;
}
.testimonial__items {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  background: var(--white);
  padding: 24px 24px;
  p {
    color: var(--themetext);
    line-height: 26px;
    font-size: 1rem;
    margin-bottom: 24px;
  }
  .star__grp {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
    i {
      font-size: 20px;
      color: var(--intellicon);
    }
  }
  .client__wrap {
    display: flex;
    align-items: center;
    gap: 10px 20px;
    .content {
      h5 {
        color: var(--themetext);
        margin-bottom: 4px;
      }
      .designation {
        font-size: 16px;
        color: var(--themetext);
      }
    }
  }
}
//Testimonial Here//
.testimonial__wrap__two {
  .testimonial__items {
    max-width: 100%;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background: var(--white);
    margin: 35px 35px 35px 5px;
    @include breakpoint(max-md) {
      margin: 20px 15px 15px 15px;
    }
    @include breakpoint(max-xs) {
      margin: 20px 5px 5px 5px;
    }
  }
  .owl-nav {
    display: flex;
    margin-top: 10px;

    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
  }
}
.testimonial__thumb__two {
  width: initial;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  @include breakpoint(max-xxl) {
    max-width: 600px;
    img {
      width: 100%;
    }
  }
  @include breakpoint(max-xl) {
    max-width: 500px;
    img {
      width: 100%;
    }
  }
  @include breakpoint(max-lg) {
    margin-bottom: 20px;
    position: initial;
    transform: translateY(-0%);
  }
}
.testimonial__content__two {
  p {
    margin-top: 24px;
    @include breakpoint(max-sm) {
      margin-top: 10px;
    }
  }
}

//Testimonial Three Here//
.testimonial__three__section {
  position: relative;
  .test__ai {
    position: absolute;
    top: 50px;
    left: 60px;
    @include breakpoint(max-xl) {
      top: 20px;
      left: 20px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__element2 {
    position: absolute;
    left: 200px;
    top: 50%;
  }
  .efect__border {
    position: absolute;
    right: 45px;
    top: 70px;
    animation: cir360 5s linear infinite;
  }
  .efect__eyollo {
    position: absolute;
    top: 30px;
    left: 50%;
    animation: cir360 5s linear infinite;
  }
  .efect__elight {
    position: absolute;
    bottom: 60px;
    left: 45%;
  }
  .testi__robot {
    position: absolute;
    right: 20px;
    bottom: 20px;
    animation: rlrl 5s linear infinite;
    @include breakpoint(max-xxl) {
      width: 140px;
      img {
        width: 100%;
      }
    }
    @include breakpoint(max-sm) {
      width: 100px;
    }
    @include breakpoint(max-xs) {
      width: 60px;
    }
  }
  .testi__tablet {
    position: absolute;
    top: 160px;
    right: 245px;
    @include breakpoint(max-xxl) {
      right: 20px;
    }
    @include breakpoint(max-xl) {
      right: 15px;
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
}
.testi__three__wrap {
  position: relative;
  z-index: 1;

  .slick-dots {
    display: flex !important;
    gap: 8px;
    bottom: -60px;

    @include breakpoint(max-xxl) {
      bottom: -40px;
    }

    li {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 10px;
      height: 10px;
      border-radius: 50% !important;

      button {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;

        background-color: var(--dots);
        width: 10px;
        height: 10px;
        margin: 0 !important;
        border-radius: 50% !important;

        &::before {
          content: "";
        }
      }

      &.slick-active {
        width: 22px;
        border-radius: 10px !important;

        button {
          width: 22px;
          border-radius: 10px !important;
          background-color: var(--base);
        }
      }
    }
  }
}
.tesiti__three__item {
  position: relative;
  z-index: 1;
  .tesiti__three__box {
    margin-bottom: 45px;
    padding: 24px 24px 24px;
    background: var(--white);
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: 100%;
      left: 30px;
      clip-path: polygon(51% 84%, 0 0, 100% 0);
      width: 40px;
      height: 40px;
      background: var(--white);
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.04);
    }
    .ratting__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .ratting {
        i {
          color: var(--intellicon);
          font-size: 22px;
        }
      }
      .quote {
        border: 1px solid rgba(69, 47, 244, 0.1);
        border-radius: 5px;
        width: 32px;
        height: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: #b5acfb;
          font-size: 24px;
        }
      }
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 150%;
      color: var(--themetext);
    }
  }
  .client__history {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    .content {
      h5 {
        color: var(--themetext);
        margin-bottom: 4px;
      }
      .designation {
        font-size: 1rem;
        color: var(--themetext);
      }
    }
  }
}
.testi__three__content {
  position: relative;
  z-index: 1;
  @include breakpoint(max-lg) {
    // margin-top: 35px;
    margin-top: 70px;
  }
  .all__user {
    display: flex;
    align-items: center;
    .user {
      margin-left: -10px;
    }
    @include breakpoint(max-md) {
      padding-left: 10px;
    }
  }
}
//Testimonial Five Here//
.testimonial__section__five {
  position: relative;
  .testimonial__wrap {
    position: initial;
    .testimonial__items {
      .quote__icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 12px;
        border: 1px solid var(--tquotebor);
        border-radius: 5px;
        margin: 0 auto 16px;
        i {
          font-size: 48px;
          color: #b5acfb;
        }
      }
      p {
        text-align: center;
      }
      .star__grp {
        justify-content: center;
      }
      .client__wrap {
        justify-content: center;
      }
    }
  }
  .owl-nav {
    position: absolute;
    left: 310px;
    bottom: 120px;
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.3s;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    @include breakpoint(max-xxl) {
      bottom: 15px;
      left: 50%;
      transform: translate(-50%);
    }
    @include breakpoint(max-sm) {
      display: none;
    }
  }
}
.testimonial__content__five {
  .section__header {
    h3 {
      color: var(--themetext);
      @include breakpoint(max-xl) {
        font-size: 1.5rem;
      }
      @include breakpoint(max-lg) {
        font-size: 2rem;
      }
      @include breakpoint(max-sm) {
        font-size: 1.5rem;
      }
    }
  }
}
//Testimonial End//

//Newsletter Here//
.newsletter__section {
  position: relative;
  overflow: hidden;
  &::before {
    background: #452ff4;
    opacity: 0.4;
    filter: blur(200px);
    width: 300px;
    height: 300px;
    content: "";
    position: absolute;
    top: -200px;
    left: -200px;
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    background: #41e295;
    opacity: 0.5;
    filter: blur(200px);
    width: 150px;
    height: 150px;
    content: "";
    position: absolute;
    top: 80px;
    right: -20px;
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .efect__element7 {
    position: absolute;
    top: 50px;
    left: 50px;
    animation: cir360 4s linear infinite;
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__element2 {
    position: absolute;
    top: 60px;
    left: 30%;
    animation: cir360 4s linear infinite;
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  .efect__cross {
    position: absolute;
    bottom: 20px;
    left: 20%;
    animation: cir360 4s linear infinite;
  }
  .efect__element8 {
    position: absolute;
    top: 50px;
    right: 20%;
    animation: cir360 4s linear infinite;
    @include breakpoint(max-sm) {
      display: none;
    }
  }
  //community
  .social__element1 {
    position: absolute;
    top: 30px;
    left: 180px;
    width: 30px;
    animation: bns1 4s linear infinite;
    img {
      width: 100%;
    }
  }
  .social__element1repet {
    position: absolute;
    bottom: 30px;
    right: 30%;
    width: 35px;
    animation: bns1 4s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .social__element2 {
    position: absolute;
    top: 180px;
    left: 280px;
    width: 30px;
    animation: workdown 4s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .social__element3 {
    position: absolute;
    bottom: 30px;
    left: 180px;
    width: 30px;
    animation: rlrl 4s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .social__element4 {
    position: absolute;
    top: 40px;
    right: 20%;
    width: 55px;
    animation: rlrl 4s linear infinite;
    img {
      width: 100%;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  .social__element5 {
    position: absolute;
    right: 10%;
    top: 50%;
    width: 35px;
    animation: workdown 4s linear infinite;
    img {
      width: 100%;
    }
  }
}
.newsletter__wrapper {
  position: relative;
  .news__element2 {
    position: absolute;
    right: -20px;
    top: 140px;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
  .news__element1 {
    position: absolute;
    left: -50px;
    top: 80px;
    @include breakpoint(max-lg) {
      display: none;
    }
  }
}
.newsletter__content {
  position: relative;
  .map__mask {
    position: absolute;
    inset: -67px 0 0 0;
  }
  .section__header {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }
  form {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(69, 47, 244, 0.05);
    border: 1px solid rgba(69, 47, 244, 0.15);
    padding: 8px;
    border-radius: 5px;
    input {
      width: 100%;
      height: 100%;
      padding: 6px 8px 10px 8px;
      background: transparent;
      color: #42526d;
      border: none;
      outline: none;
    }
    button {
      border: none;
      padding-right: 40px;
    }
    ::placeholder {
      color: #42526d;
    }
    @include breakpoint(max-sm) {
      padding: 8px;
      input {
        padding: 6px 6px 8px 6px;
        font-size: 14px;
      }
      button {
        font-size: 14px;
        padding: 11px 25px 14px 15px;
      }
    }
  }
  .join__btn {
    position: relative;
    z-index: 1;
    text-align: center;
  }
}
.testimonial__thumb {
  position: relative;
  width: 100%;
  img {
    width: 100%;
    animation: bns1 5s linear infinite;
  }
  .testi__dots {
    position: absolute;
    top: 100px;
    left: 0;
    @include breakpoint(max-xs) {
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .testi__swtich {
    position: absolute;
    bottom: 30px;
    left: 0;
    @include breakpoint(max-xs) {
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .testi__pinterest {
    position: absolute;
    right: 0;
    bottom: 30px;
    @include breakpoint(max-xs) {
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .testi__bell {
    position: absolute;
    bottom: 30%;
    right: -200px;
    width: 80px;
    img {
      width: 100%;
    }
    @include breakpoint(max-xs) {
      width: 30px;
      img {
        width: 100%;
      }
    }
  }
  .testi__facebook {
    position: absolute;
    top: 0;
    width: 40px;
    img {
      width: 100%;
    }
    right: -150px;
    @include breakpoint(max-lg) {
      top: 50px;
    }
    @include breakpoint(max-xs) {
      width: 30px;
      right: -2px;
      img {
        width: 100%;
      }
    }
  }
  @include breakpoint(max-sm) {
    display: none;
  }
}
//Newsletter Three Start//
.newsletter__section__three {
  .newsletter__wrapper {
    .newsletter__content {
      .social__part {
        margin-top: 35px;
        display: flex;
        align-items: center;
        gap: 15px 24px;
        @include breakpoint(max-xs) {
          flex-wrap: wrap;
        }
        .follow__btn {
          a {
            display: flex;
            align-items: center;
            gap: 4px;
            span {
              font-size: 1.2rem;
              color: var(--themetext);
            }
            .icon {
              margin-top: 10px;
              i {
                font-size: 22px;
                color: var(--themetext);
              }
            }
          }
        }
        .social__standard {
          display: flex;
          align-items: center;
          gap: 5px 12px;
          li {
            width: 40px;
            height: 40px;
            line-height: 35px;
            text-align: center;
            border-radius: 5px;
            background: var(--soc1);
            border: 1px solid var(--socbor1);
            a {
              i {
                color: var(--base);
                font-size: 20px;
              }
            }
            &:nth-child(2) {
              background: var(--soc2);
              border-color: var(--socbor2);
              a {
                i {
                  color: var(--basetwo);
                }
              }
            }
            &:nth-child(3) {
              background: var(--soc3);
              border-color: var(--socbor3);
              a {
                i {
                  color: var(--basethree);
                }
              }
            }
            &:nth-child(4) {
              background: var(--soc4);
              border-color: var(--socbor4);
              a {
                i {
                  color: var(--themeneed);
                }
              }
            }
          }
        }
      }
    }
  }
}
.newsletter__three__thumb {
  width: 100%;
  animation: bns1 5s linear infinite;
  img {
    width: 100%;
  }
  @include breakpoint(max-lg) {
    margin-top: 35px;
  }
}
//Newsletter Four Here//
.newsletter__wrapper__four {
  background: var(--nbg1);
  border-radius: 5px;
  overflow: hidden;
  padding: 75px 20px 80px;
  margin-top: -180px;
  transform: translateY(180px);
  z-index: 9;
  form {
    background: var(--white);
    input {
      background: var(--white);
    }
  }
  .newsfour__element1 {
    position: absolute;
    top: 56px;
    left: 101px;
    width: 250px;
    @include breakpoint(max-lg) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .newsfour__element2 {
    position: absolute;
    bottom: 32px;
    right: 110px;
    width: 250px;
    @include breakpoint(max-lg) {
      display: none;
    }
    img {
      width: 100%;
    }
  }
  .newsfour__ball1 {
    position: absolute;
    top: 45px;
    right: 134px;
    @include breakpoint(max-md) {
      top: 20px;
      right: 20px;
      width: 60px;
      img {
        width: 100%;
      }
    }
  }
  .newsfour__ball2 {
    position: absolute;
    bottom: 34px;
    left: 80px;
    width: 80px;
    img {
      width: 100%;
    }
  }
}
//Newsletter End//

//Brighter Here//
.brighter__future {
  overflow: hidden;
  position: relative;
}
.brighter__wrap {
  background: var(--brighterbg);
  padding: 100px 30px 0;
  @include breakpoint(max-md) {
    padding: 60px 10px 0;
  }
  .brighter__content {
    max-width: 750px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 100px;
    .d3 {
      margin-bottom: 24px;
      color: var(--themetext);
    }
    p {
      color: var(--themetext);
      font-size: 1rem;
      margin-bottom: 35px;
    }
    .cmn__grp {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px 24px;
      .cmn__border {
        color: var(--prightercolor);
        background: unset;
        border: 1px solid var(--prighterborder);
      }
    }
    @include breakpoint(max-md) {
      padding-bottom: 60px;
      .d3 {
        font-size: 3rem;
      }
    }
    @include breakpoint(max-sm) {
      .d3 {
        font-size: 2rem;
      }
    }
    @include breakpoint(max-xs) {
      .d3 {
        margin-bottom: 20px;
        font-size: 1.5rem;
      }
      p {
        margin-bottom: 25px;
      }
      .cmn__grp {
        flex-wrap: wrap;
      }
    }
  }
  .brighter__thumb {
    margin: 0 auto;
    text-align: center;
    background: var(--laptopbox);
    max-width: 900px;
    border-radius: 440px 440px 0 0;
    .thumb {
      text-align: center;
      margin: 0 auto;
      @include breakpoint(max-md) {
        max-width: 300px;
        padding: 10px 10px 0 0;
        img {
          width: 100%;
        }
      }
    }
  }
}
//Brighter End//

//help Here//
.help__box {
  background: var(--helpbox);
  border: 1px solid var(--helpboxbor);
  padding: 32px 32px;
  border-radius: 5px;
  text-align: center;
  .icon {
    width: 72px;
    height: 72px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    background: var(--helpicon);
    border: 1px solid var(--helpiconborder);
    border-radius: 5px;
    margin: 0 auto 24px;
    i {
      font-size: 24px;
      color: var(--helpiconi);
    }
  }
  .icon2 {
    background: var(--helpicon2);
    border: 1px solid var(--helpiconborder2);
  }
  .icon3 {
    background: var(--helpicon3);
    border: 1px solid var(--helpiconborder3);
  }
  .cont {
    h5 {
      color: var(--themetext);
      margin-bottom: 12px;
    }
    a {
      font-size: 16px;
      font-weight: 400;
      color: var(--themetext);
      display: block;
    }
  }
}
//help End//

//Blog Grid Here//
.blog__grid__items {
  .thumb {
    overflow: hidden;
    img {
      transition: all 0.4s;
      border-radius: 5px;
    }
  }
  .content {
    h4 {
      margin-bottom: 16px;
      a {
        transition: all 0.4s;
      }
    }
    .admin__wrap {
      display: flex;
      align-items: center;
      gap: 10px 32px;
      margin-bottom: 20px;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          margin-top: 5px;
          i {
            font-size: 18px;
            color: var(--achackcion);
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: var(--place2);
        }
      }
    }
    @include breakpoint(max-xl) {
      padding: 24px 10px;
    }
  }
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
    .content {
      h4 {
        a {
          color: var(--base);
        }
      }
    }
  }
}
.blog__list__items {
  padding: 8px 8px 8px;
  border-color: var(--white);
  .content {
    width: calc(100% - 300px);
    padding: 10px 0px;
    h4 {
      margin-bottom: 16px;
    }
    .admin__wrap {
      display: flex;
      align-items: center;
      gap: 10px 32px;
      margin-bottom: 15px;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          margin-top: 5px;
          i {
            font-size: 18px;
            color: var(--achackcion);
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: var(--place2);
        }
      }
    }
  }
  .thumb {
    margin-top: -0px;
    max-width: 300px;
    height: 253px;
    img {
      height: 100%;
    }
  }
  @include breakpoint(max-xxl) {
    gap: 24px 15px;
    .content {
      width: calc(100% - 250px);
      h4 {
        font-size: 1.2rem;
      }
    }
    .thumb {
      max-width: 250px;
      height: 250px;
    }
  }
  @include breakpoint(max-xl) {
    .content {
      width: calc(100%);
      padding-left: 22px;
      h4 {
        font-size: 1.2rem;
      }
    }
    .thumb {
      max-width: 100%;
      height: 100%;
    }
  }
  @include breakpoint(max-xs) {
    .content {
      padding-left: 10px;
    }
  }
}
.blog__details__head {
  h2 {
    color: var(--themetext);
  }
  padding-bottom: 40px;
  @include breakpoint(max-sm) {
    padding-bottom: 20px;
    h2 {
      font-size: 30px;
    }
  }
  @include breakpoint(max-xs) {
    h2 {
      font-size: 26px;
      line-height: 36px;
    }
  }
}
.blog__details__wrap {
  .blog__grid__items {
    margin-bottom: 30px;
  }
  .owl-nav {
    position: absolute;
    top: -80px;
    // top: -100px;
    right: 0;
    button.owl-prev {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      transition: all 0.3s;
      background-color: transparent;
      i {
        font-size: 28px;
        color: var(--themetext);
        transition: all 0.3s;
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    button.owl-next {
      line-height: 6px;
      border: 1px solid var(--base);
      width: 40px;
      height: 40px;
      border-radius: 5px;
      transition: all 0.3s;
      background-color: transparent;
      i {
        font-size: 28px;
        line-height: 20px;
        color: var(--themetext);
      }
      &:hover {
        background: var(--base);
        i {
          color: var(--white);
        }
      }
    }
    @include breakpoint(max-sm) {
      position: initial;
      display: flex;
    }
  }
}
//Blog Grid End//

//shop Here//
.main__cart__wrap {
  background: var(--shopbg);
  border-radius: 5px;
}
.shop__items {
  padding: 8px;
  background: var(--shopbg);
  border-radius: 5px;
  // @TODO: edit
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.06);
  // box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  .thumb {
    background: var(--shopthumbbg);
    border-radius: 5px;
    padding: 10px;
    img {
      border-radius: 5px;
      width: 100%;
      overflow: hidden;
      transition: all 0.4s;
    }
  }
  .content {
    padding: 24px 20px;
    @include breakpoint(max-xl) {
      padding: 20px 10px;
    }
    h4 {
      color: var(--themetext);
      margin-bottom: 12px;
      a {
        color: var(--themetext);
      }
    }
    .price__wrap {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 8px;
      .price {
        color: var(--achackcion);
        font-size: 16px;
      }
      .price_hide {
        color: var(--place2);
        font-size: 16px;
        text-decoration: line-through;
      }
    }
    .ratting {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 24px;
      span {
        i {
          font-size: 22px;
          color: var(--intellicon);
        }
      }
    }
    .shop__btn {
      display: flex;
      align-items: center;
      gap: 20px;
      .bg__trns {
        background: transparent;
        border: 1px solid var(--inputborder);
        color: var(--themetext);
      }
    }
  }
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.shop__side__bar {
  @include breakpoint(max-lg) {
    margin-top: 35px;
  }
  .title {
    a {
      color: var(--themetext);
    }
  }
  .cmn__items {
    &:not(:last-child) {
      border-bottom: 1px solid var(--filterborder);
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
    .cmn__head {
      color: var(--themetext);
      margin-bottom: 16px;
    }
    form {
      border-radius: 5px;
      background: transparent;
    }
    .categoris__lis {
      .form-check {
        display: flex;
        align-content: center;
        gap: 12px;
        label {
          font-size: 16px;
          font-weight: 400;
          color: var(--com);
        }
        .form-check-input {
          box-shadow: none;
          outline: none;
          border: 1px solid var(--placeinput);
          background-color: transparent;
        }
        .form-check-input:checked {
          background-color: transparent;
        }
        .form-check-input:checked[type="checkbox"] {
          --bs-form-check-bg-image: url(../../public/img/shop/done.png);
          background-size: 10px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
    .Cart__wrap {
      background: var(--shopthumbbg);
      border-radius: 5px;
      padding: 20px;
      position: relative;
      @include breakpoint(max-xl) {
        padding: 10px;
      }
      .cross__icon {
        position: absolute;
        right: 20px;
        top: 20px;
        i {
          font-size: 24px;
          color: #ed5050;
        }
      }
      .cart__head {
        display: flex;
        align-items: center;
        gap: 12px;
        .thumb {
          background: var(--smalllux);
          border-radius: 5px;
          padding: 10px;
        }
        .head__content {
          h5 {
            font-size: 16px;
            color: var(--prightercolor);
            margin-bottom: 8px;
          }
          .luxeprice {
            font-size: 14px;
            font-weight: 400;
            color: var(--place2);
          }
        }
      }
      .subprice {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 20px 0;
        .sub {
          color: var(--themetext);
        }
        .price {
          color: var(--robit);
        }
      }
      .shop__btn {
        display: flex;
        align-items: center;
        gap: 20px;
        @include breakpoint(max-xl) {
          gap: 10px;
          .cmn--btn {
            padding: 8px 15px 10px;
          }
        }
        .bg__trns {
          border: 1px solid var(--checkoutborder);
          color: var(--themetext);
          background: transparent;
        }
      }
    }
    .pricing__bar {
      .range-slider {
        width: 100%;
        text-align: center;
        position: relative;
        .rangeValues {
          display: block;
          color: var(--com);
          font-size: 14px;
          font-weight: 400;
          padding-top: 12px;
        }
      }
      input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        position: absolute;
        left: 0;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        width: 300px;
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
      }
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 6px;
        border-radius: 11px;
        background: #fff;
        border: 1px solid var(--base);
        margin-top: -6px;
        cursor: pointer;
        position: relative;
        z-index: 1;
      }
      input[type="range"]:focus {
        outline: none;
      }
      input[type="range"]:focus::-webkit-slider-runnable-track {
        background: #ccc;
      }
      input[type="range"]::-moz-range-track {
        width: 300px;
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
      }
      input[type="range"]::-moz-range-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #21c1ff;
      }
      input[type="range"]:-moz-focusring {
        outline: 1px solid white;
        outline-offset: -1px;
      }
      input[type="range"]::-ms-track {
        width: 300px;
        height: 5px;
        background: transparent;
        border-color: transparent;
        border-width: 6px 0;
        color: transparent;
        z-index: -4;
      }
      input[type="range"]::-ms-fill-lower {
        background: #777;
        border-radius: 10px;
      }
      input[type="range"]::-ms-fill-upper {
        background: #ddd;
        border-radius: 10px;
      }
      input[type="range"]::-ms-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #21c1ff;
      }
      input[type="range"]:focus::-ms-fill-lower {
        background: #888;
      }
      input[type="range"]:focus::-ms-fill-upper {
        background: #ccc;
      }
    }
  }
}
//Single Product
.ladies__thumb {
  background: var(--shopthumbbg);
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 30px 40px;
  width: 100%;
  img {
    width: 100%;
  }
}
.ladies__content {
  h3 {
    color: var(--themetext);
    margin-bottom: 24px;
  }
  .content {
    padding-top: 12px;
    p {
      font-size: 1rem;
      font-weight: 400;
      color: var(--themetext);
    }
  }
  form {
    input {
      color: var(--themetext);
    }
    ::placeholder {
      color: var(--themetext);
    }
    .qtyplus,
    .qtyminus {
      background: red;
    }
  }
  .describtion__reviews {
    padding: 0 0px;
    .nav {
      border: none;
      display: flex;
      align-items: center;
      gap: 20px;
      .nav-item {
        border: none;
        .nav-link {
          padding: 0 0;
          border: none;
          font-size: 1.2rem;
          font-weight: 500;
          color: var(--themetext);
          &.active {
            color: var(--achackcion);
            background: none;
          }
        }
      }
    }
  }
  .product-description__content-footer {
    margin-top: 14px;
  }

  .product-description__content-footer .product-pricing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .product-description__content-footer .product-pricing-single {
    padding: 10px 20px;
    background: var(--brighterbg);
    border-radius: 5px;
    .product-color-variations {
      label {
        display: flex;
        align-items: center;
        gap: 8px;
        input {
          border: none;
          box-shadow: none;
          outline: none;
          width: 30px;
          height: 30px;
          background: none;
          border-radius: 100%;
          overflow: hidden;
          display: block;
        }
        i {
          font-size: 24px;
          color: var(--themetext);
        }
      }
    }
    p {
      color: var(--robit);
    }
  }

  .product-description__content-footer .product-pricing-calculator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    .product-quant {
      color: var(--themetext);
    }
    .cart-incre,
    .cart-decre {
      i {
        color: var(--achackcion);
      }
    }
  }

  .product-description__content-footer
    .product-pricing-calculator
    span:nth-of-type(1),
  .product-description__content-footer
    .product-pricing-calculator
    span:nth-of-type(3) {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: var(--place2bor);
    border: 1px solid var(--minusborder);
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
  }
}
.mfp-container {
  z-index: 9999999;
}
//female bas section
.female__img {
  width: 100%;
  img {
    width: 100%;
    border-radius: 5px;
  }
}
.female__review {
  @include breakpoint(max-lg) {
    margin-bottom: 35px;
  }
  .write__review__wrap {
    margin-top: 40px;
    .title {
      margin-bottom: 28px;
      color: var(--themetext);
    }
    form {
      input {
        width: 100%;
        background: var(--offbg);
        border: 1px solid var(--offborder);
        color: var(--offtext);
        padding: 12px 24px;
        outline: none;
        border-radius: 5px;
      }
      .form__star {
        border-radius: 5px;
        width: 100%;
        background: var(--offbg);
        border: 1px solid var(--offborder);
        color: var(--offtext);
        padding: 12px 24px;
        a {
          i {
            font-size: 22px;
            color: var(--intellicon);
          }
        }
      }
      ::placeholder {
        color: var(--offtext);
      }
      textarea {
        background: var(--offbg);
        border: 1px solid var(--offborder);
        color: var(--offtext);
        padding: 12px 24px;
        outline: none;
        box-shadow: none;
      }
      .cmn--btn {
        width: 160px;
        border: none;
        outline: none;
        margin-left: 10px;
      }
    }
  }
}
//shop End//

//shop cart Here//
.cart__wrapper {
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  background: var(--shopbg);
  padding: 40px 40px;
  .cart-items-wrapper {
    table {
      width: 100%;
      thead {
        border-bottom: 1px solid var(--cartnewborde);
        tr {
          th {
            padding-bottom: 16px;
            color: var(--com);
            font-size: 18px;
          }
        }
      }
      tbody {
        .cart-item {
          .cart-item-info {
            display: flex;
            align-items: center;
            gap: 16px;
            img {
              background: var(--shopthumbbg);
              border-radius: 5px;
            }
            .secondary-text {
              font-size: 1rem;
              color: var(--com);
            }
          }
          .cart-item-price {
            color: var(--achackcion);
          }
          td {
            border-bottom: 1px solid var(--cartnewborde);
            padding: 16px 0;
            .cart-item-quantity {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              background: var(--shopthumbbg);
              width: 100px;
              border-radius: 5px;
              padding: 2px 20px;
              span {
                color: var(--themetext);
                display: block;
              }
              .cart-item-quantity-controller {
                a {
                  display: block;
                  &:not(:last-child) {
                    margin-bottom: -12px;
                  }
                  i {
                    color: var(--themetext);
                  }
                }
              }
            }
          }
          .cart-item-remove {
            a {
              i {
                font-size: 22px;
                color: var(--removecross);
              }
            }
          }
        }
      }
    }
  }
  .cart__wrapper-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    form {
      border: 1px solid var(--cartnewborde);
      padding: 8px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        color: var(--place2);
        padding: 5px 5px;
        border: none;
        font-weight: 500;
        outline: none;
        background: transparent;
      }
      ::placeholder {
        color: var(--place2);
      }
      .cmn--btn {
        border: none;
        outline: none;
      }
    }
  }
  @include breakpoint(max-md) {
    overflow-x: scroll;
    .cart-items-wrapper {
      width: 700px;
    }
    .cart__wrapper-footer {
      width: 700px;
    }
  }
}
.cart__pragh__box {
  margin-top: 24px;
  padding-right: 30px;
  padding-bottom: 30px;
  .cart__graph {
    border: 1px solid var(--checkbor1);
    background: var(--cartbgbox1);
    padding: 30px 30px;
    border-radius: 5px;
    h5 {
      text-align: center;
      color: var(--themetext);
      margin-bottom: 30px;
    }
    ul {
      margin-bottom: 30px;
      li {
        display: flex;
        &:not(:last-child) {
          border-bottom: 1px solid var(--checkbor1);
        }
        span {
          width: calc(100% - 50%);
          color: var(--prightercolor);
          font-size: 18px;
          font-weight: 500;
          padding: 16px 0;
        }
        .bg {
          font-weight: 400;
          background: var(--brighterbg);
          padding: 16px 24px;
        }
        .tprice {
          color: var(--robit);
        }
      }
    }
    .chck {
      text-align: center;
    }
  }
}
//shop cart End//

//shop checkout Here//
.successful__section {
  padding: 240px 0 120px;
  @include breakpoint(max-lg) {
    padding: 200px 0px;
  }
  @include breakpoint(max-xs) {
    padding: 180px 0px;
  }
}
.checkout__radio {
  .primary-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 16px;
    color: var(--themetext);
  }
  background: var(--brighterbg);
  padding: 24px;
  h4 {
    color: var(--com);
    margin-bottom: 16px;
  }
  .checkout__radio-wrapper {
    .checkout__radio-single {
      .form-check-input {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        box-shadow: none;
        outline: none;
        border: 1px solid var(--payborder);
      }
      label {
        color: var(--com);
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  @include breakpoint(max-xs) {
    padding: 10px;
  }
}
.checkout__single-wrapper {
  .checkout__single {
    h4 {
      color: var(--themetext);
      margin-bottom: 2rem;
    }
    .checkout__single-form {
      .input-single {
        textarea,
        .country-select,
        input {
          width: 100%;
          outline: none;
          box-shadow: none;
          border: 1px solid var(--place2bor);
          border-radius: 5px;
          background-color: var(--brighterbg);
          padding: 12px 24px;
          color: var(--themetext);
        }
        label {
          color: var(--com);
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        ::placeholder {
          color: var(--themetext);
        }
      }
      .payment {
        color: var(--robit);
        margin-bottom: 12px;
      }
    }
  }
  .boxshado__single {
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background: var(--shopbg);
    padding: 32px;
    margin-bottom: 24px;
    @include breakpoint(max-sm) {
      padding: 14px;
    }
  }
  .checkout__single__bg {
    background: var(--brighterbg);
    padding: 32px;
    .checkout__single-form {
      .input-single {
        textarea,
        .country-select,
        input {
          background: var(--shopbg);
        }
      }
    }
    @include breakpoint(max-sm) {
      padding: 14px;
    }
  }
}
.payment__success__inner {
  border: 1px solid var(--succborder);
  background: var(--paymentfull);
  border-radius: 5px;
  padding: 32px;
  .payment__success__header {
    text-align: center;
    margin-bottom: 24px;
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--achackcion);
      margin: 0 auto 20px;
      i {
        color: var(--white);
        font-size: 24px;
      }
    }
    h2 {
      color: var(--themetext);
      margin-bottom: 20px;
    }
    p {
      font-size: 24px;
      font-weight: 400;
      color: var(--themetext);
    }
    @include breakpoint(max-sm) {
      h2 {
        font-size: 26px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .payment__success__body {
    padding: 14px 24px;
    background: var(--paymentfullbody);
    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
          border-bottom: 1px solid var(--idborder);
        }
        span {
          color: var(--themetext);
          font-size: 1rem;
          font-weight: 400;
          padding: 12px 0;
        }
        .textbo {
          font-weight: 500;
        }
      }
    }
    margin-bottom: 24px;
    @include breakpoint(max-sm) {
      padding: 14px 14px;
      li {
        span {
          font-size: 14px;
        }
      }
    }
  }
  .payment__success__footer {
    .payment-success__footer-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px 24px;
      a {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          margin-top: 5px;
          i {
            font-size: 20px;
            color: var(--achackcion);
          }
        }
        span {
          color: var(--themetext);
          font-size: 1rem;
        }
      }
      @include breakpoint(max-sm) {
        flex-wrap: wrap;
      }
    }
    .dbutton {
      text-align: center;
      margin-top: 32px;
    }
  }
  @include breakpoint(max-sm) {
    padding: 15px;
  }
}
.payment__save {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  input {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid var(--dotsave);
    background-color: var(--shopbg);
    outline: none;
  }
  label {
    font-size: 16px;
    font-weight: 500;
    color: var(--com);
  }
}
//shop checkout End//

.divided__common__body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  .side__sticky {
    width: 270px;
    position: fixed;
    height: 100vh;
    @include breakpoint(max-xxl) {
      width: 240px;
      .common__sidebar__wrapper {
        padding: 20px 15px 180px;
      }
    }
  }
  @include breakpoint(max-xl) {
    flex-wrap: wrap;
    .side__sticky {
      position: initial;
      width: 100%;
    }
    .common__body {
      margin-left: 0px;
      width: 100%;
    }
  }
}
//Blocks section Here//
.common__section {
  padding: 130px 0 30px;
  // overflow: hidden;
}
.common__sidebar__wrapper {
  background: var(--brighterbg);
  border-radius: 5px;
  padding: 20px 30px 150px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 17px -4px,
    rgba(0, 0, 0, 0.3) 0px 1px 0px -9px;
  max-height: 100vh;
  overflow-y: scroll;
  @include breakpoint(max-xl) {
    padding: 20px 30px 40px !important;
  }
}
.common__left__item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .blocks__menu {
    .head__title {
      margin-bottom: 10px;
      .title {
        font-size: 1.2rem;
        color: var(--themetext);
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
        background: var(--idborder);
        padding: 3px 7px 7px;
        border-radius: 5px;
      }
    }
    li {
      a {
        color: var(--themetext);
        font-weight: 500;
        display: block;
        transition: all 0.4s;
      }
      &:hover {
        a {
          color: var(--white);
        }
      }
      &:not(:first-child) {
        border-bottom: 1px solid var(--sunborder);
        border-radius: 5px;
        padding: 9px 0px 12px;
        transition: all 0.4s;
        &:hover {
          background: var(--base);
          padding: 9px 12px 12px;
        }
        &.active {
          background: var(--base);
          padding: 9px 12px 12px;
          a {
            color: var(--white);
          }
        }
      }
    }
  }
}
.common__body {
  background: var(--blockbody);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 17px -4px,
    rgba(0, 0, 0, 0.3) 0px 1px 0px -9px;
  border-radius: 5px;
  color: var(--themetext);
  padding: 30px;
  margin-left: 270px;
  max-width: 100%;
  width: 85%;
  .cmn__title {
    color: var(--themetext);
    border-bottom: 1px solid var(--idborder);
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  @include breakpoint(max-xxl) {
    padding: 30px 10px;
    width: 78%;
  }
  @include breakpoint(max-sm) {
    padding: 20px 3px;
  }
}
.common__body__section {
  .common__body__head {
    h4 {
      margin-bottom: 20px;
      color: var(--themetext);
    }
    .nav {
      display: flex;
      align-items: center;
      gap: 10px;
      .nav-item {
        border: none;
        .nav-link {
          border: none;
          padding: 0%;
          background: transparent;
          color: var(--themetext);
          font-weight: 500;
          border: 1px solid var(--sunborder);
          border-radius: 2px;
          padding: 5px 20px 7px;
          &.active {
            background: transparent;
            color: var(--base);
          }
        }
      }
    }
  }
}
.prettyprint {
  background: #000;
}
.typography__wrapper {
  .typography__items {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--themetext);
    }
    @include breakpoint(max-sm) {
      .d1 {
        font-size: 3.1rem;
        font-weight: 600;
        line-height: 120%;
      }
      .d2 {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 120%;
      }
      .d3 {
        font-size: 2rem;
        font-weight: 600;
        line-height: 120%;
      }
      .d4 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 120%;
      }
    }
  }
  .body__text {
    .body__text__item {
      .texttitle {
        font-size: 18px;
        font-weight: 700;
        color: var(--themetext);
        margin-bottom: 10px;
        display: block;
      }
      .lead {
        font-size: 24px;
        font-weight: 700;
        max-width: 700px;
        line-height: 150%;
        @include breakpoint(max-sm) {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .xl {
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        max-width: 700px;
        @include breakpoint(max-sm) {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .lg {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        max-width: 700px;
        @include breakpoint(max-sm) {
          font-size: 14px;
        }
      }
      .md {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        max-width: 700px;
        @include breakpoint(max-sm) {
          font-size: 14px;
        }
      }
      .sm {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        max-width: 700px;
      }
      .xs {
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        max-width: 700px;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--idborder);
      }
    }
  }
}
//block home
.blocks__home {
  //bannre style here
  .banner__mask {
    margin-bottom: -2px;
  }
  .banner__section {
    .banner__content {
      padding-top: 20px;
      .d3 {
        font-size: 48px;
      }
      .btg__grp {
        @include breakpoint(max-xs) {
          flex-wrap: wrap;
        }
      }
    }
    .banner__wrapper {
      @include breakpoint(max-xl) {
        padding-top: 60px;
      }
    }
  }
  .banner__section__two {
    .banner__wrapper {
      padding-top: 100px;
      .banner__content {
        .btg__grp {
          @include breakpoint(max-xs) {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
  .banner__section__three {
    .banner__wrapper {
      padding-top: 50px;
      .banner__content {
        .content__box {
          .d3 {
            font-size: 48px;
            @include breakpoint(max-xs) {
              font-size: 29px;
            }
          }
          .btg__grp {
            @include breakpoint(max-xs) {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  .banner__section__four {
    .banner__wrapper {
      padding-top: 100px;
      .banner__content {
        .d3 {
          @include breakpoint(max-xxl) {
            font-size: 48px;
          }
          .rounded {
            svg {
              width: 160px;
              top: -10px;
            }
          }
        }
        .btg__grp {
          @include breakpoint(max-xs) {
            flex-wrap: wrap;
          }
        }
      }
      @include breakpoint(max-sm) {
        padding-top: 60px;
      }
    }
  }
  .banner__section__five {
    .banner__wrapper {
      padding-top: 90px;
    }
    .banner__content {
      .d3 {
        font-size: 48px;
      }
      .btg__grp {
        @include breakpoint(max-xs) {
          flex-wrap: wrap;
        }
      }
    }
  }
  //about style here
  .about__section {
    .about__thumb {
      .brain__element {
        max-width: 150px;
        left: -120px;
        img {
          width: 100%;
        }
      }
    }
  }
  .about__section__two {
    .about__thumb {
      max-width: 550px;
      top: 0;
      @include breakpoint(max-xxl) {
        max-width: 400px;
      }
      @include breakpoint(max-xl) {
        max-width: 500px;
      }
    }
  }
  .about__section__four {
    .about__thumb {
      @include breakpoint(lg) {
        max-width: 450px;
      }
    }
  }
  //facts
  .make__service__section {
    .airobot {
      @include breakpoint(lg) {
        width: 80px;
        img {
          width: 100%;
        }
      }
    }
  }
  //main counter fact include
  .main__counter__project {
    .project__count__items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px 24px;
      text-align: left;
      .icon {
        margin: 0;
        background: transparent;
        border: none;
        i {
          font-size: 48px;
        }
      }
      .counter__items {
        text-align: left !important;
        .counter__content {
          .cont {
            justify-content: flex-start;
            text-align: left;
          }
        }
      }
      padding: 20px 20px;
      border-radius: 5px;
      background: var(--box1);
      border: 1px solid var(--inbox1);
    }
    .project__count__itemstwo {
      background: var(--box2);
      border: 1px solid var(--boxtwoborderpro);
    }
    .project__count__itemsthree {
      background: var(--inbox4);
      border: 1px solid var(--boxborderpro);
    }
    .project__count__itemsfive {
      background: var(--pribox4);
      border: 1px solid var(--fiveicon4bor);
    }
  }
  //case studies
  .capabilities__section {
    .capa__ai {
      top: 10px;
      left: 10px;
      width: 40px;
    }
  }
  .case__different__section {
    .capabilities__items {
      @include breakpoint(max-xxl) {
        display: grid;
        .content {
          width: calc(100%);
          margin: 0 -0px 0px;
        }
      }
    }
  }
  //testimonial
  .testimonial__section {
    .testimonial__thumb__two {
      @include breakpoint(max-xxl) {
        max-width: 400px;
      }
    }
  }
  //newsletter
  .newsletter__section__four {
    .newsletter__wrapper {
      margin-top: -0px;
      transform: translateY(0px);
    }
  }
  //faw
  .faq__section__four {
    .accordion__four__contnt {
      position: relative;
      z-index: 1;
    }
    .accordion-item {
      position: relative;
      z-index: 1;
    }
  }
}
//Blocks section End//

//Contact Here//
.contact__section {
  position: relative;
  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    content: "";
    background: var(--bgsection);
    width: 100%;
  }
}
.form_area {
  background: var(--machinelearning);
  box-shadow: 0px 6px 30px rgba(47, 65, 129, 0.08);
  border-radius: 5px;
  padding: 32px 32px;
  position: relative;
  .title {
    margin-bottom: 50px;
    font-weight: 700;
  }
  label {
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    color: var(--com);
    margin-bottom: 16px;
  }
  .select-wrapper {
    background: var(--contactinptbg);
    border-radius: 5px;
    margin-bottom: 30px;
    select {
      background: var(--contactinptbg);
      border: none;
      border-radius: 5px;
      width: 100%;
      outline: none;
      width: 100%;
      height: 50px;
      outline: none;
      color: var(--place2);
      border: 1px solid var(--place2bor);
      padding: 0 10px;
    }
  }
  #form {
    position: relative;
    .form-control {
      padding: unset;
      border: none;
      background-color: transparent;
      position: relative;
      input {
        background: var(--contactinptbg);
        border: none;
        padding: 5px 15px 8px;
        border-radius: 5px;
        width: 100%;
        height: 50px;
        outline: none;
        color: var(--place2);
        border: 1px solid var(--place2bor);
        &::placeholder {
          color: var(--place2);
        }
      }
    }
    .form-control textarea {
      background: var(--contactinptbg);
      border: none;
      padding: 5px 15px 8px;
      border-radius: 5px;
      width: 100%;
      outline: none;
      color: var(--place2);
      border: 1px solid var(--place2bor);
    }
    ::placeholder {
      color: var(--place2);
    }
    .form-control textarea:focus {
      outline: 0;
      border: none;
    }
    .form-control.success textarea {
      border-color: #2ecc71;
    }
    .form-control.error textarea {
      border-color: #e74c3c;
    }
    .form-control input:focus {
      outline: 0;
      border: none;
    }
    .form-control.success input {
      border-color: #2ecc71;
    }

    .form-control.error input {
      border-color: #e74c3c;
    }

    .form-control small {
      color: #e74c3c;
      position: absolute;
      bottom: -20px;
      left: 0;
      visibility: hidden;
    }

    .form-control.error small {
      visibility: visible;
    }
    .cmn--btn {
      border: none;
      outline: none;
      &:hover {
        border: none;
      }
    }
    .thank_you {
      p {
        position: absolute;
        bottom: -30px;
        color: green;
        animation: scaleone 0.5s linear;
        transition: all 0.5s;
        display: none;
        @keyframes scaleone {
          0% {
            transform: scale(0.8);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }
}
//Contact End//

//Footer Here//
.footer__section {
  background: var(--banner);
  position: relative;
  overflow: hidden;
  &::before {
    position: absolute;
    bottom: 50px;
    left: -220px;
    content: "";
    width: 300px;
    height: 300px;
    background: #ffaf00;
    opacity: 0.6;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  &::after {
    position: absolute;
    bottom: 50px;
    right: -220px;
    content: "";
    width: 300px;
    height: 300px;
    background: #41e295;
    opacity: 0.6;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  .footer__shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    img {
      width: 100%;
    }
  }
  .footer__darkshpae {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    display: none;
    img {
      width: 100%;
    }
  }
  .footer__mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.footer__top {
  &::before {
    position: absolute;
    bottom: 400px;
    left: 50%;
    transform: translate(-50%);
    content: "";
    width: 250px;
    height: 250px;
    background: #452ff4;
    opacity: 0.8;
    filter: blur(200px);
    @include breakpoint(max-xs) {
      width: 250px;
    }
  }
  position: relative;
  z-index: 1;
  padding: 270px 0 120px;
  @include breakpoint(max-xl) {
    padding: 190px 0 100px;
  }
  @include breakpoint(max-lg) {
    padding: 160px 0 90px;
  }
  @include breakpoint(max-sm) {
    padding: 110px 0 80px;
  }
  .footer__widget {
    .widget__head {
      h4 {
        color: var(--white);
      }
      margin-bottom: 25px;
      .footer__logo {
        display: block;
        width: 240px;
        @include breakpoint(max-xl) {
          width: 200px;
        }
        img {
          width: 100%;
        }
      }
    }
    .widget__link {
      .link {
        display: block;
        font-size: 16px;
        line-height: 150%;
        font-weight: 400;
        color: var(--white);
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        position: relative;
        &::before {
          position: absolute;
          left: 0;
          top: 100%;
          content: "";
          width: 0px;
          height: 1px;
          background: #ffffff2e;
          transition: all 0.9s;
        }
        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
      .footer__contact__items {
        display: flex;
        align-items: center;
        gap: 8px 12px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        .icon {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 5px;
          background: var(--base);
          span {
            color: var(--white);
          }
          i {
            color: #fff;
          }
        }
        .icontwo {
          background: var(--basetwo);
        }
        .iconthree {
          background: var(--basethree);
        }
        .fcontact__content {
          width: calc(100% - 32px);
          font-size: 16px;
          font-weight: 400;
          color: var(--footertext);
          line-height: 150%;
        }
      }
    }
    p {
      font-size: 1rem;
    }
    form {
      border: 1px solid #3021ab;
      border-radius: 5px;
      padding: 8px 8px;
      display: flex;
      align-items: center;
      input {
        width: 100%;
        border: none;
        background: unset;
        outline: none;
      }
      ::placeholder {
        color: var(--white);
      }
      .cmn--btn {
        border: none;
        padding: 8px 15px 6px;
        span {
          i {
            font-size: 18px;
            color: var(--white);
          }
        }
      }
    }
  }
}
.footer__bottom {
  position: relative;
  z-index: 1;
  padding: 30px 0;
  border-top: 1px solid rgba(168, 158, 250, 0.15);
  p {
    font-size: 16px;
    color: var(--footertext);
    line-height: 150%;
    text-align: center;
    .intellicon {
      color: var(--intellicon);
    }
  }
}
.social {
  display: flex;
  align-items: center;
  gap: 8px;
  li {
    .social__item {
      display: block;
      width: 32px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      background: var(--base);
      i {
        color: #fff;
      }
    }
    .social__itemtwo {
      background: var(--basetwo);
    }
    .social__itemthree {
      background: var(--basethree);
    }
    .social__itemfour {
      background: #a21111;
    }
  }
}
.footer__bottom__two {
  display: flex;
  justify-content: space-between;
  .footer__bottom__link {
    display: flex;
    align-items: center;
    gap: 10px 24px;
  }
  p {
    @include breakpoint(max-lg) {
      order: 1;
    }
  }
  @include breakpoint(max-lg) {
    flex-wrap: wrap;
    justify-content: center;
    .footer__bottom__link {
      margin-bottom: 15px;
      @include breakpoint(max-xs) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 2px 20px;
      }
    }
  }
}
//Footer End//

//Footer Three//
.footer__three {
  .footer__wrapper {
    .footer__top__three {
      padding: 60px 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      .f_logo {
        max-width: 200px;
        display: block;
        img {
          width: 100%;
        }
      }
      .f__three__link {
        display: flex;
        align-items: center;
        gap: 14px 24px;
      }
      .social {
        display: flex;
        align-items: center;
        gap: 8px;
        li {
          a {
            i {
              color: var(--white);
              font-size: 18px;
            }
          }
        }
      }
      @include breakpoint(max-lg) {
        display: grid;
        text-align: center;
        justify-content: center;
        .f_logo {
          margin: 0 auto 15px;
        }
        .f__three__link {
          justify-content: center;
          margin-bottom: 20px;
        }
        .social {
          justify-content: center;
        }
      }
      @include breakpoint(max-xs) {
        .f__three__link {
          flex-wrap: wrap;
          gap: 4px 20px;
        }
      }
    }
  }
}
.social {
  li {
    width: 32px;
    height: 32px;
    line-height: 38px;
    text-align: center;
    display: block;
    background: var(--facebook);
    border-radius: 5px;
    a {
      i {
        font-size: 24px;
        color: var(--white);
      }
    }
    &:nth-child(2) {
      background: var(--instagram);
    }
    &:nth-child(3) {
      background: var(--twitter);
    }
    &:nth-child(4) {
      background: var(--linkedin);
    }
  }
}
//Footer four Here//
.footer__section__four {
  padding-top: 30px;
  @include breakpoint(max-xl) {
    padding-top: 90px;
  }
  @include breakpoint(max-sm) {
    padding-top: 140px;
  }
}
//Footer Five Here//
.footer__section__five {
  .footer__top {
    padding: 120px 0 120px;
    @include breakpoint(max-xl) {
      padding: 100px 0 100px;
    }
    @include breakpoint(max-lg) {
      padding: 80px 0 80px;
    }
  }
}
//Footer End//

/**================================================== *
 * ==========  slick slide  ========== *
 * ================================================== */

.slick-slide {
  img {
    display: inline-block;
  }
}

.slick-list {
  margin: 0px -12px;
}
.slick-slide {
  & > div {
    margin: 0 12px;
  }
}
/* =======  End of slick slide  ======= */

/**================================================== *
 * ==========  scroll to top  ========== *
 * ================================================== */
.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: var(--base);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  cursor: pointer;
  transition: all 0.6s;
  transform: translateY(100%);
  border: none;

  &:hover {
    background-color: var(--success);
  }

  span {
    color: #fff;
    font-size: 18px;
    line-height: 45px;
  }
  &.active {
    bottom: 30px;
    transform: translateY(0%);
  }
}

/* =======  End of scroll to top  ======= */
